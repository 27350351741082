// Slice for user
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UserContext } from '../context/UserContext';
import { getTokenFromStorage, removeTokenFromStorage, saveTokenToStorage } from '../utils/Storage';

const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_REST_API ?? '' }),
  tagTypes: ['User'],
  endpoints: builder => ({
    getUser: builder.query<User, string>({
      query: (walletId: string) => `/user/${walletId}`,
      providesTags: ['User'],
      transformResponse: (response: User, meta, arg) => {
        /**
         * Save the access token to localstorage
         */
        const token = meta?.response?.headers.get('token');

        if (token) {
          saveTokenToStorage(token);
        }

        return response;
      }
    }),
    getUserViaToken: builder.query<User, string>({
      query: (token: string) => `/user/token/${token}`,
      providesTags: ['User'],
      transformResponse: (response: User, meta, arg) => {
        /**
         * Save the access token to localstorage
         */
        const token = meta?.response?.headers.get('token');

        if (token) {
          saveTokenToStorage(token);
        }

        return response;
      }
    }),
    addUser: builder.mutation<User, { name: string, patch: { walletId: string, key: string }}>({
      query: ({name, patch}) => ({
        url: `/user/${name}`,
        method: 'PUT',
        body: patch
      }),
      invalidatesTags: ['User'],
      transformResponse: (response: User, meta, arg) => {
        /**
         * Save the access token to localstorage
         */
        const token = meta?.response?.headers.get('token');

        if (token) {
          saveTokenToStorage(token);
        } else {
          removeTokenFromStorage();
        }

        return response;
      }
    }),
    updateBio: builder.mutation<User, { name: string, patch: { username: string, bio: string, email?: string }}>({
      query: ({name, patch}) => ({
        url: `/user/${name}`,
        method: 'PATCH',
        body: { ...patch, token: getTokenFromStorage() }
      }),
      invalidatesTags: ['User'],
    }),
    updateAvatar: builder.mutation<User, { name: string, patch: { avatarImage: string }}>({
      query: ({name, patch}) => ({
        url: `/user/${name}`,
        method: 'PATCH',
        body: { ...patch, token: getTokenFromStorage() },
        invalidatesTags: ['User'],
      })
    }),
    updateBanner: builder.mutation<User, { name: string, patch: { bannerImage: string } }>({
      query: ({ name, patch }) => ({
        url: `/user/${name}`,
        method: 'PATCH',
        body: { ...patch, token: getTokenFromStorage() }
      }),
      invalidatesTags: ['User'],
    }),
    updateWyvernContract: builder.mutation<User, { name: string, patch: { contractId: string, wyvernAddress: string, token: string, walletId: string } }>({
      query: ({ name, patch }) => ({
        url: `/user/wyvern/${name}`,
        method: 'PATCH',
        body: { ...patch, token: getTokenFromStorage() }
      }),
      invalidatesTags: ['User'],
    })
  })
});

/**
 * Hooks export
 */
export const { useGetUserQuery, useLazyGetUserQuery, useLazyGetUserViaTokenQuery, useAddUserMutation, useUpdateBioMutation, useUpdateAvatarMutation, useUpdateBannerMutation, useUpdateWyvernContractMutation } = userApi;

/**
 * Export the state
 * of the getUser query
 */
export const useGetUserQueryState = userApi.endpoints.getUser.useQueryState;

export default userApi;
