import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LanguageContext } from '../context/LanguageContext';
import { NotificationContext } from '../context/NotificationContext';
import { UserContext } from '../context/UserContext';
import { useAddCollectionMutation } from '../features/collectionsApi';
import Loader from './Loader';
import WalletConnector from './WalletConnector';

interface Props {
  className?: string
}

function CollectionImporter(props: Props) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [addCollection] = useAddCollectionMutation();
  const [contractId, setcontractId] = useState<string>('');
  const [initiated, setInitiated] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { setNotification } = useContext(NotificationContext);
  const { currentLanguage } = useContext(LanguageContext);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (contractId !== '' && !initiated && user) {
      setInitiated(true);
      setIsError(false);

      addCollection({ name: contractId, patch: { contractId, owner: user.walletId }}).then(response => {
        if ('error' in response) {
          console.log(response)
          setInitiated(false);
          setIsError(true);

          if ('data' in response.error) {
            setErrorMessage((response.error.data as { error: string }).error as string);
          }
          
          setNotification(currentLanguage.addCollection.form.errorNotification);
        } else {
          navigate(`/collection/${contractId}`);
  
          setNotification(currentLanguage.addCollection.form.successNotification);
        }
      }).catch(err => {
        setNotification(currentLanguage.addCollection.form.errorNotification);

        console.error(err)
      })
    } else {
      setIsError(true);
    }
  }

  return (
    <div className={props.className}>
      {user &&
        <div className='add-collection' onSubmit={handleSubmit}>
          <form action='PUT'>
            <p>{currentLanguage.addCollection.form.label}</p>

            <input type='text' className='form-field' placeholder='0x0000...' onChange={e => setcontractId(e.target.value)}/>

            {isError &&
              <p className='form-error'>{errorMessage}</p>
            }

            <button type='submit' className='btn'>
              <>
                <Loader width={10} height={10} isLoading={initiated}>{currentLanguage.addCollection.form.button}</Loader>
              </>
            </button>
          </form>
        </div>
      }

      {!user &&
        <div className='connect-wallet'>
          <p>You need to connect your wallet before you can import a collection!</p>

          <WalletConnector />
        </div>
      }
    </div>
  )
}

export default styled(CollectionImporter)`
  .add-collection {
    max-width: 450px;
    margin: 0 auto;
    text-align: center;

    .btn {
      margin-top: 10px; 
    }
  }

  .connect-wallet {
    text-align: center;
  }
`;