import styled from 'styled-components';

interface Props {
  className?: string,
  id: string,
  avatar: string,
  withborder?: boolean
}

// Component
function CardOwner(props: Props) {
  return (
    <div className={`${props.className} ${props.withborder ? 'with-border' : ''}`}>
      <img src={props.avatar} alt='' width='150' height='150' />

      <i className='fa-solid fa-check'></i>
    </div>
  )
}

// Styles
export default styled(CardOwner)`
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  &.with-border {
    width: 60px;
    height: 60px;
    padding: 5px;
    background: ${props => props.theme.colors.primary};

    i {
      bottom: 5px;
      right: 5px;
    }
  }

  i {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 2px;
    bottom: 2px;
    border-radius: 50%;
    font-size: 10px;
    line-height: 16px;
    color: #fff;
    text-align: center;
    background: ${props => props.theme.colors.quaternary};
  }
  img {
    border-radius: 50%;
  }
`;