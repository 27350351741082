import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { LanguageContext } from '../context/LanguageContext';
import { NotificationContext } from '../context/NotificationContext';
import { UserContext } from '../context/UserContext'
import { useUpdateBioMutation } from '../features/userApi';
import Loader from './Loader';

interface Props {
  className?: string
}

function ProfileBioEditor(props: Props) {
  const { user, setUser } = useContext(UserContext);
  const [updateUser, result] = useUpdateBioMutation();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const { setNotification } = useContext(NotificationContext);
  const { currentLanguage } = useContext(LanguageContext);

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formElements = e.currentTarget.elements as typeof e.currentTarget.elements & {
      username: { value: string },
      email: { value: string },
      bio: { value: string }
    };

    if (
      formElements.username.value === '' ||
      formElements.bio.value === ''
    ) {
      setNotification(currentLanguage.editProfile.bioAlert);

      return;
    }

    if (!isInitialized && user) {
      setIsInitialized(true);

      updateUser({ name: user.walletId, patch: { username: formElements.username.value, bio: formElements.bio.value, email: formElements.email.value }}).then(response => {
        if ('data' in response) {
          setUser(response.data);
        }

        setNotification(currentLanguage.editProfile.bioSuccessNotification);

        setIsInitialized(false);
      }).catch(err => {
        setNotification(currentLanguage.editProfile.errorNotification);

        console.error(err)
      })
    }
  }

  return (
    <div className={props.className}>
      <form action='PATCH' onSubmit={handleSubmit}>
        <div className='form-row'>
          <label htmlFor='username' className='form-label'>{currentLanguage.editProfile.username} <span className='required'>*</span></label>

          <input type='text' id='username' name='username' className='form-field' defaultValue={user?.username} />
        </div>

        <div className='form-row'>
          <label htmlFor='email' className='form-label'>Email</label>

          <input type='email' id='email' name='email' className='form-field' defaultValue={user?.email} />
        </div>

        <div className='form-row'>
          <label htmlFor='bio' className='form-label'>{currentLanguage.editProfile.about} <span className='required'>*</span></label>

          <textarea className='form-field' name='bio' id='bio' maxLength={400} defaultValue={user?.bio}></textarea>
        </div>

        <div className='form-row'>
          <button type='submit' className='btn'>
            <Loader isLoading={isInitialized} width={10} height={10}>{currentLanguage.editProfile.profileButton}</Loader>
          </button>
        </div>
      </form>
    </div>
  )
}

export default styled(ProfileBioEditor)`

`;
