import { rgba } from 'polished';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LanguageContext } from '../context/LanguageContext';
import { useGetCollectionQuery } from '../features/collectionsApi';
import { useGetNftsForContractQuery } from '../features/nftApi';
import { getShortenedAddress } from '../utils/Helpers';
import CollectionSidebar from './CollectionSidebar';
import Columns from './Columns';
import Loader from './Loader';
import NFTCard from './NFTCard';

interface Props {
  className?: string,
  contractId: string
}

function CollectionShowcase(props: Props) {
  const [isForSale, setIsForSale] = useState<boolean>(false);
  const [isForAuction, setIsForAuction] = useState<boolean>(false);
  const [lowPrice, setLowPrice] = useState<number>();
  const [highPrice, setHighPrice] = useState<number>();
  const [name, setName] = useState<string>();
  const [sortBy, setSortBy] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [nfts, setNfts] = useState<Array<NFT>>([]);
  const [stopFetching, setStopFetching] = useState<boolean>(false);
  const [traits, setTraits] = useState<string>();
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const collectionData = useGetCollectionQuery(props.contractId);
  const nftData = useGetNftsForContractQuery({ 
    contractId: props.contractId,
    isForSale: isForSale ? true : undefined,
    isForAuction: isForAuction ? true : undefined,
    gte: lowPrice,
    lte: highPrice,
    page,
    name,
    sortBy,
    traits
  });
  const { currentLanguage } = useContext(LanguageContext);

  useEffect(() => {
    if (nftData.data && !nftData.isLoading && !nftData.isFetching) {
      setNfts([...nfts, ...nftData.data]);

      setStopFetching(nftData.data.length === 0);
    }
  }, [nftData.data, page])

  const resetState = () => {
    setNfts([]);

    setPage(1);
  }

  return (
    <Loader isLoading={(collectionData.isLoading && nftData.isLoading)}>
      <div className={props.className}>
        <h2 className='collection-name'>{collectionData.data?.name}</h2>

        {collectionData.data?.isSettingUp === true &&
          <p className='text-center'>{currentLanguage.collectionShowcase.settingUp}</p>
        }

        {collectionData.data?.isSettingUp === false &&
          <>
            {collectionData.data?.description &&
              <p className='collection-description'>{collectionData.data.description}</p>
            }

            <div className='collection-info'>
              <div><strong>{currentLanguage.collectionShowcase.owner}</strong> <Link to={`/user/${collectionData.data?.ownerId}`}>{getShortenedAddress(collectionData.data?.ownerId ?? '')}</Link></div>

              <div><strong>{currentLanguage.collectionShowcase.floor}</strong> {(collectionData.data?.floorPrice && collectionData.data?.floorPrice > 0) ? collectionData.data.floorPrice : 'N/A'}</div>

              <div><strong>{currentLanguage.collectionShowcase.owners}</strong> {collectionData.data?.owners}</div>

              <div><strong>{currentLanguage.collectionShowcase.supply}</strong> {collectionData.data?.totalSupply}</div>
            </div>

            <div className='collection-meta'>
              {collectionData.data?.twitter && collectionData.data?.twitter !== '' &&
                <a href={`https://twitter.com/${collectionData.data.twitter}`} target='_blank'><i className="fa-brands fa-twitter"></i> {collectionData.data?.name} twitter</a>
              }

              {collectionData.data?.website && collectionData.data?.website !== '' &&
                <a href={collectionData.data.website} target='_blank'><i className="fa-solid fa-globe"></i> {collectionData.data?.name} website</a>
              }

              {collectionData.data?.discord && collectionData.data?.discord !== '' &&
                <a href={collectionData.data.discord} target='_blank'><i className="fa-brands fa-discord"></i> {collectionData.data?.name} discord</a>
              }

              {collectionData.data?.instagram && collectionData.data?.instagram !== '' &&
                <a href={collectionData.data.instagram} target='_blank'><i className="fa-brands fa-instagram"></i> {collectionData.data?.name} instagram</a>
              }
            </div>

            <div className='collection-nfts'>
              <CollectionSidebar
                isForSale={isForSale}
                setIsForSale={(data: boolean) => { setIsForSale(data); resetState(); }}
                setIsForAuction={(data: boolean) => { setIsForAuction(data); resetState(); }}
                setLowPrice={(data?: number) => { setLowPrice(data); resetState(); }}
                setHighPrice={(data?: number) => { setHighPrice(data); resetState(); }}
                setName={(data?: string) => { setName(data); resetState(); }}
                setSortBy={(data?: string) => { setSortBy(data); resetState(); }}
                // traits={collectionData.data?.traitOccurances}
                setTraits={(data?: string) => { setTraits(data); resetState(); }}
                isOpen={filtersOpen}
              />

              <Loader isLoading={nftData.isLoading}>
                {
                  (() => {
                    if (nfts.length || nftData.isFetching) {
                      return (
                        <div className='collection-items'>
                          <Columns data={nfts} columns={4} component={NFTCard} page={page} setPage={(data: number) => setPage(data)} shouldFetch={stopFetching ? false : !nftData.isFetching} />

                          <Loader isLoading={nftData.isFetching}><></></Loader>
                        </div>
                      )
                    } else {
                      return <p>{currentLanguage.collectionShowcase.noResults}</p>
                    }
                  })()
                }
              </Loader>
            </div> 

            <div className={`filters-toggle ${filtersOpen ? 'filters-open' : ''}`} onClick={e => setFiltersOpen(!filtersOpen)}>
              <i className="fa-solid fa-filter"></i>

              <i className="fa-solid fa-xmark"></i>
            </div>
          </>
        }
      </div>
    </Loader>
  )
}

export default styled(CollectionShowcase)`
  h2 {
    text-align: center;
  }

  .columns-4 {
    
    @media only screen and (max-width: ${props => props.theme.breakpoints.md}) {
      > * {
        flex: 0 0 45%;
      }
    }
  }
  .filters-toggle {
    display: none;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      position: fixed;
      display: block;
      width: 40px;
      height: 40px;
      bottom: 10px;
      left: 10px;
      border-radius: 50%;
      box-shadow: 0 0 4px ${props => rgba(props.theme.colors.secondary, .4)};
      line-height: 40px;
      color: ${props => props.theme.colors.secondary};
      text-align: center;
      background: ${props => props.theme.colors.tertiary};
      transition: transform .15s;
      z-index: 5;

      i {

        &:last-child {
          display: none;
        }
      }

      &.filters-open {
        transform: translateX(280px);

        i {

          &:first-child {
            display: none;
          }
          &:last-child {
            display: inline-block;
          }
        }
      }
    }
  }
  .collection-description {
    text-align: center;
  }
  .collection-nfts {
    display: flex;
    align-items: flex-start;

    ${CollectionSidebar} {
      flex: 0 0 260px;
      margin-right: 25px;
    }

    .collection-items {
      flex: 0 1 100%;
    }
  }
  .collection-meta {
    margin-bottom: 20px;
    text-align: center;

    a {
      display: inline-block;
      margin: 0 15px;
      color: ${props => props.theme.colors.primary};
      vertical-align: middle;
      text-decoration: none;
      transition: color .15s;
      font-size: 0;
      line-height: 0;
      text-indent: -4000px;

      &:hover {
        color: ${props => props.theme.colors.quaternary};
      }

      i {
        display: block;
        font-size: 30px;
        text-indent: 0;
      }
    }
  }
  .collection-info {
    display: flex; 
    justify-content: center;
    margin-bottom: 25px; 

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      max-width: 274px;
      flex-flow: row wrap;
      margin-right: auto;
      margin-left: auto;
      border: 2px solid ${props => props.theme.colors.primary};
      border-radius: 10px;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    > div {
      flex: 0 0 135px;
      padding: 10px 15px;
      border: 2px solid ${props => props.theme.colors.primary};
      text-align: center;

      @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
        border: 0;
      }

      + div {
        border-left: 0;

        @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
          border-radius: 0 !important;
          
          &:nth-child(even) {
            border-left: 2px solid ${props => props.theme.colors.primary};
          }
          &:nth-child(n+3) {
            border-top: 2px solid ${props => props.theme.colors.primary};
          }
        }
      }
      &:first-child {
        border-radius: 10px 0 0 10px;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
      }

      strong {
        display: block;
        margin-bottom: 5px;
        color: ${props => props.theme.colors.secondary};
      }
    }
  }
  ${CollectionSidebar} {

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      position: fixed;
      width: 280px;
      height: 100%;
      top: 0;
      left: 0;
      border-width: 0 2px 0 0;
      border-radius: 0;
      transform: translateX(-100%);
      overflow-x: hidden;
      overflow-y: auto;
      background: ${props => props.theme.colors.tertiary};
      transition: transform .15s;
      z-index: 6;

      &.filters-open {
        transform: translateX(0);
      }
    }
  }
`;