import React, { useContext } from 'react'
import styled from 'styled-components'
import { LanguageContext } from '../context/LanguageContext'

interface Props {
  className?: string,
  nft: NFT,
  collection: Collection
}

function NFTMiniShowcase(props: Props) {
  const { currentLanguage } = useContext(LanguageContext);

  return (
    <div className={props.className}>
      <div className='image' style={{ backgroundImage: `url(${props.nft.metadata.media})` }}></div>

      <div className='content'>
        <div className='name'><strong>NFT:</strong> {props.nft.metadata.title}</div>

        <div className='collection'><strong>{currentLanguage.nftMiniShowcase.collection}</strong> {props.collection.name}</div>

        <div className='price'><strong>{currentLanguage.nftMiniShowcase.price}</strong> {props.nft.salePrice}</div>
      </div>
    </div>
  )
}

export default styled(NFTMiniShowcase)`
  display: flex;
  padding: 10px;
  margin-bottom: 10px;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 10px;

  .content {
    
    strong {
      color: ${props => props.theme.colors.secondary};
    }
  }
  .image {
    flex: 0 0 80px;
    height: 80px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
`;