import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';

interface Props {
  className?: string,
  title?: string,
  background?: string
}

function IntroAlt(props: Props) {
  return (
    <ScrollAnimation animateIn='animate__fadeIn' animateOnce={true} offset={0}>
      <div className={props.className} style={{ backgroundImage: `url(${props.background ?? ''})` }}>
        {props.title &&
          <div className='container'>
            <h1>{props.title}</h1>
          </div>
        }
      </div>
    </ScrollAnimation>
  )
}

export default styled(IntroAlt)`
  padding: 130px 0;
  margin-bottom: 90px;
  text-align: center;
  background: ${props => props.theme.colors.quinary};
  background-size: cover;
  background-position: center center;

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: 50px;
  }

  h1 {
    color: ${props => props.theme.colors.secondary};
  }
`;