import React from 'react'
import styled from 'styled-components'
import ProfileBioEditor from './ProfileBioEditor';
import ProfileImagesEditor from './ProfileImagesEditor';

interface Props {
  className?: string
}

function ProfileEditor(props: Props) {
  return (
    <div className={props.className}>
      <ProfileBioEditor />

      <ProfileImagesEditor />
    </div>
  )
}

export default styled(ProfileEditor)`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-flow: row wrap-reverse;
  }

  ${ProfileBioEditor} {
    flex: 0 0 47%;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      flex: 0 0 100%;
    }
  }
  ${ProfileImagesEditor} {
    flex: 0 0 40%;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      flex: 0 0 100%;
      margin-bottom: 40px;
    }
  }
`;