import React, { useState } from 'react'
import AppContextProvider from './AppContext';
import UserContextProvider from './UserContext';
import { Provider } from 'react-redux';
import { store } from '../app/store';
import { ThemeContext } from 'styled-components';
import Theme from '../styles/Theme';
import GlobalStyle from '../styles/globalStyles';
import NotificationContextProvider from './NotificationContext';
import LanguageContextProvider from './LanguageContext';

interface Props {
  children: Array<React.ReactChild> | React.ReactChild
}

export default function RootProvider(props: Props) {
  return (
    <Provider store={store}>
      <AppContextProvider>
        <LanguageContextProvider>
          <UserContextProvider>
            <NotificationContextProvider>
              <ThemeContext.Provider value={Theme}>
                <GlobalStyle />

                {props.children}
              </ThemeContext.Provider>
            </NotificationContextProvider>
          </UserContextProvider>
        </LanguageContextProvider>
      </AppContextProvider>
    </Provider>
  )
}
