import { nanoid } from '@reduxjs/toolkit';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useGetTopSellersQuery } from '../features/usersApi';
import { getShortenedAddress } from '../utils/Helpers';
import Loader from './Loader';

interface Props {
  className?: string
}

function Topsellers(props: Props) {
  const topSellersData = useGetTopSellersQuery();

  return (
    <Loader isLoading={topSellersData.isLoading}>
      {
        (() => {
          if (topSellersData.data?.length) {
            return (
              <ol className={props.className}>
                {
                  topSellersData.data?.map(topSeller => {
                    return (
                      <li key={nanoid()}>
                        <Link to={`/user/${topSeller.walletId}`}>{getShortenedAddress(topSeller.walletId)}</Link> {topSeller.totalProfit} Ⓝ
                      </li>
                    );
                  })
                }
              </ol>
            );
          } else {
            return <p className='text-center'>No users available!</p>
          }
        })()
      }
      
    </Loader>
  )
}

export default styled(Topsellers)`
  padding: 0;
  columns: 3;
  font-size: 14px;
  color: ${props => props.theme.colors.secondary};
  list-style: none;
  counter-reset: nums;

  @media only screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    display: flex;
    flex-flow: column nowrap;
    columns: unset;
    align-items: center;
  }

  li {
    position: relative;
    display: block;
    vertical-align: middle;
    column-break-inside: avoid;
    margin-bottom: 25px;
    counter-increment: nums;

    &:before {
      content: counter(nums)'.';
      position: absolute;
      top: 0;
      right: 100%;
      margin-right: 5px;
      font-size: 16px;
    }
  }
  a {
    display: block;
    margin-bottom: 3px;
    font-size: 16px;
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
  }
`;