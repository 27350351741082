import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import IntroAlt from '../components/IntroAlt';
import ProfileEditor from '../components/ProfileEditor';
import Section from '../components/Section';
import { AppContext } from '../context/AppContext';
import { UserContext } from '../context/UserContext';

export default function EditProfile() {
  const { setHeaderIsAlt } = useContext(AppContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  document.title = 'Edit profile';

  useEffect(() => {
    setHeaderIsAlt(true);

    if (!user) {
      navigate('/');
    }
  }, [])

  return (
    <>
      {user &&
        <>
          <IntroAlt background={user.bannerImage} />
          
          <main className='main'>
            <div className='container'>
              <Section>
                <ProfileEditor />
              </Section>
            </div>
          </main>
        </>
      }
    </>
  )
}
