/**
 * Save the authorization token
 * to browser local storage
 * @param { string } token
 */
export const saveTokenToStorage = (token: string) => {
  window.localStorage.setItem('token', token);
}

/**
 * Remove tokens from storage
 */
export const removeTokenFromStorage = () => {
  window.localStorage.removeItem('token');
}

/**
 * Get the stored token from 
 * browser local storage
 */
export const getTokenFromStorage = () => {
  return window.localStorage.getItem('token');
}