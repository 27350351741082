import { read } from 'fs';
import React, { useContext, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import { LanguageContext } from '../context/LanguageContext';
import { NotificationContext } from '../context/NotificationContext';
import { useLinkTwitterMutation, useUpdateCollectionMutation } from '../features/collectionsApi';
import { getTokenFromStorage } from '../utils/Storage';
import { twitterOauth } from '../utils/Twitter';
import Loader from './Loader';

interface Props {
  className?: string,
  data: Collection
}

function CollectionEditor(props: Props) {
  const formRef = useRef<HTMLFormElement>(null);
  const formData = JSON.parse(window.localStorage.getItem('formData') ?? '{}');
  const [updateCollection, result] = useUpdateCollectionMutation();
  const [name, setName] = useState<string>(formData.name ?? props.data.name);
  const [description, setDescription] = useState<string>(formData.description ?? props.data.description);
  const [category, setCategory] = useState<string>(formData.category ?? props.data.category);
  const [website, setWebsite] = useState<string>(formData.website ?? props.data.website);
  const [discord, setDiscord] = useState<string>(formData.discord ?? props.data.discord);
  const [instagram, setInstagram] = useState<string>(formData.instagram ?? props.data.instagram);
  const [avatarImage, setAvatarImage] = useState<string>(formData.avatarImage ?? props.data.avatarImage);
  const [bannerImage, setBannerImage] = useState<string>(formData.bannerImage ?? props.data.bannerImage);
  const [isInitiated, setIsInitiated] = useState<boolean>(false);
  const [twitterIsLinking, setTwitterIsLinking] = useState<boolean>(false);
  const [unlinkTwitter] = useLinkTwitterMutation();
  const { setNotification } = useContext(NotificationContext);
  const navigate = useNavigate();
  const { currentLanguage } = useContext(LanguageContext);

  /**
   * Read image as base64
   */
  const imageToBase64 = (el: HTMLInputElement, set: (image: string) => void) => {
    const reader = new FileReader();

    if (el.files) {
      reader.readAsDataURL(el.files[0]);

      reader.onload = file => {
        set(reader.result as string);
      }

      reader.onerror = err => {
        setNotification('An error has occurred!');

        console.error(err);
      }
    }
  }

  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (
      name === '' ||
      description === '' ||
      category === ''
    ) {
      alert(currentLanguage.editCollection.alertNotification);

      return;
    }

    if (formRef.current && !isInitiated) {
      setIsInitiated(true);

      const filePromises: Array<Promise<number>> = [];

      await Promise.all(filePromises);

      window.localStorage.removeItem('formData');

      await updateCollection({
        name,
        description,
        category,
        contractId: props.data.contractId,
        avatarImage: avatarImage ?? '',
        bannerImage: bannerImage ?? '',
        website,
        discord,
        instagram,
        token: getTokenFromStorage() ?? ''
      }).then(response => {
        setNotification(currentLanguage.editCollection.successNotification);
        
        navigate(`/collection/${props.data.contractId}`);
      }).catch(err => {
        setNotification(currentLanguage.editCollection.errorNotification);

        console.error(err)
      });
    }
  }

  const initiateTwitterConnection = async () => {
    if (!twitterIsLinking) {
      setTwitterIsLinking(true);

      window.localStorage.setItem('formData', JSON.stringify({
        name,
        description,
        category,
        avatarImage: avatarImage,
        bannerImage: bannerImage,
        website,
        discord,
        instagram
      }));
      window.localStorage.setItem('collection', props.data.contractId);
  
      await twitterOauth();
    }
  }

  return (
    <div className={props.className}>
      <form action='PATCH' onSubmit={handleSubmit} ref={formRef}>
        <h2>{currentLanguage.editCollection.title}</h2>

        {props.data.isSettingUp === true &&
          <p className='text-center'>{currentLanguage.editCollection.settingUp}</p>
        }

        {props.data.isSettingUp === false &&
          <>
            <div className='form-row'>
              <div className='h4'>{currentLanguage.editCollection.collectionImages}</div>

              <label htmlFor='upload-avatar' className='form-label'>{currentLanguage.editCollection.avatarImage}</label>

              <div className='form-file'>
                <div className='preview' style={{ backgroundImage: `url(${avatarImage ?? props.data.avatarImage})` }}></div>

                <span className='btn'>{currentLanguage.editCollection.selectFile}</span>

                <input type='file' name='upload-avatar' id='upload-avatar' accept='image/png, image/jpeg' onChange={e => imageToBase64(e.currentTarget, setAvatarImage)} />
              </div>
            </div>

            <div className='form-row'>
              <label htmlFor='upload-banner' className='form-label'>{currentLanguage.editCollection.bannerImage}</label>

              <div className='form-file'>
                <div className='preview banner' style={{ backgroundImage: `url(${bannerImage ?? props.data.bannerImage})` }}></div>

                <span className='btn'>{currentLanguage.editCollection.selectFile}</span>

                <input type='file' name='upload-banner' id='upload-banner' accept='image/.png' onChange={e => imageToBase64(e.currentTarget, setBannerImage)} />
              </div>
            </div>

            <div className='form-row'>
              <div className='h4'>{currentLanguage.editCollection.colletionData}</div>

              <label htmlFor='name' className='form-label'>{currentLanguage.editCollection.name} <span className='required'>*</span></label>

              <input type='text' name='name' id='name' className='form-field' defaultValue={name} onChange={e => setName(e.currentTarget.value)} />
            </div>

            <div className='form-row'>
              <label htmlFor='description' className='form-label'>{currentLanguage.editCollection.description} <span className='required'>*</span></label>

              <textarea name='description' id='description' className='form-field' defaultValue={description} onChange={e => setDescription(e.currentTarget.value)} />
            </div>

            <div className='form-row'>
              <label htmlFor='category' className='form-label'>{currentLanguage.editCollection.categories.label} <span className='required'>*</span></label>

              <div className='form-select'>
                <select name='category' id='category' className='form-field' defaultValue={category ?? ''} onChange={e => setCategory(e.currentTarget.value)}>
                  <option value=''>{currentLanguage.editCollection.categories.options.category}</option>
                  <option value='Art'>{currentLanguage.editCollection.categories.options.art}</option>
                  <option value='Music'>{currentLanguage.editCollection.categories.options.music}</option>
                  <option value='Sports'>{currentLanguage.editCollection.categories.options.sports}</option>
                  <option value='Collectibles'>{currentLanguage.editCollection.categories.options.collectibles}</option>
                  <option value='Trading Cards'>{currentLanguage.editCollection.categories.options.cards}</option>
                </select>
              </div>
            </div>

            <div className='form-row'>
              <div className='h4'>{currentLanguage.editCollection.social}</div>

              <div className='form-combined-fields'>
                <input type='text' className='form-field' name='website' id='website' placeholder='yousite.io' defaultValue={website ?? ''} onChange={e => setWebsite(e.currentTarget.value)} />

                <input type='text' className='form-field' name='discord' id='discord' placeholder='Discord link' defaultValue={discord ?? ''} onChange={e => setDiscord(e.currentTarget.value)} />

                <input type='text' className='form-field' name='instagram' id='instagram' placeholder='Instagram link' defaultValue={instagram ?? ''} onChange={e => setInstagram(e.currentTarget.value)} />
              </div>
            </div>

            <div className='form-row twitter-connect'>
              {(!props.data.twitter || props.data.twitter === '') &&
                <span className='btn' onClick={initiateTwitterConnection}>
                  <Loader isLoading={twitterIsLinking} width={10} height={10}>{currentLanguage.editCollection.twitterButton}</Loader>
                </span>
              }

              {props.data.twitter && props.data.twitter !== '' &&
                <>
                  <a href={`https://twitter.com/${props.data.twitter}`} target="_blank"><i className="fa-brands fa-twitter"></i> {props.data.twitter}</a>

                  <span onClick={() => unlinkTwitter({ twitterId: '', contractId: props.data.contractId, token: getTokenFromStorage() ?? '' })}><i className="fa-solid fa-xmark"></i></span>
                </>
              }
            </div>

            <div className='form-row form-actions'>
              <button type='submit' className='btn'>
                <Loader isLoading={result.isLoading} width={10} height={10}>{currentLanguage.editCollection.save}</Loader>
              </button>

              <Link to='/my-collections' className='btn btn-alt' onClick={e => window.localStorage.removeItem('formData')}>{currentLanguage.editCollection.cancel}</Link>
            </div>
          </>
        }
      </form>
    </div>
  )
}

export default styled(CollectionEditor)`
  max-width: 700px;
  margin: 0 auto;

  .h4 {
    padding-top: 25px;
  }
  .twitter-connect {

    a {
      font-size: 20px;
      color: ${props => props.theme.colors.primary};
      text-decoration: none;
      transition: color .15s;

      &:hover {
        color: ${props => props.theme.colors.quaternary};
      }
    }
    span {

      &:not(.btn) {
        display: inline-block;
        margin-left: 5px;
        vertical-align: middle;
        color: ${props => props.theme.colors.quaternary};
        transition: color .15s;
        cursor: pointer;
  
        &:hover {
          color: ${props => props.theme.colors.septenary};
        }
      }
    }
  }
  .preview {
    width: 150px;
    height: 150px;
    margin: 10px 0;
    border-radius: 50%;
    background-position: center center;
    background-size: cover;

    &.banner {
      width: 310px;
      height: 170px;
      border-radius: 10px;
    }
  }
  .form-actions {
    padding-top: 25px;

    .btn {

      + .btn {
        margin-left: 10px;
      }
    }
  }
`;