const Theme = {
  light: {
    animationOffset: .65,
    colors: {
      primary: '#55DAF8',
      secondary: '#103D80',
      tertiary: '#FFFFFF',
      quaternary: '#E02B58',
      quinary: '#EEF6FA',
      senary: '#4F607A',
      septenary: 'linear-gradient(90deg, #7DF0C6 0%, #55DAF8 100%)'
    },
    fonts: {
      sansSerif: 'Inter',
      sansSerifSecondary: 'Montserrat'
    },
    breakpoints: {
      lg: '1365px',
      md: '1023px',
      sm: '767px',
      xs: '539px'
    }
  },
  dark: {
    animationOffset: .65,
    colors: {
      primary: '#55DAF8',
      secondary: '#FFFFFF',
      tertiary: '#0E294F',
      quaternary: '#F87596',
      quinary: '#EEF6FA',
      senary: '#153B73',
      septenary: 'linear-gradient(90deg, #7DF0C6 0%, #55DAF8 100%)'
    },
    fonts: {
      sansSerif: 'Inter',
      sansSerifSecondary: 'Montserrat'
    },
    breakpoints: {
      lg: '1365px',
      md: '1023px',
      sm: '767px',
      xs: '539px'
    }
  }
};

export default Theme.light;