import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CollectionShowcase from '../components/CollectionShowcase';
import IntroCollection from '../components/IntroCollection';
import Section from '../components/Section';
import { AppContext }  from '../context/AppContext';

export default function Collection() {
  const { setHeaderIsAlt } = useContext(AppContext);
  const { contractId } = useParams();

  document.title = 'Collection';

  useEffect(() => {
    setHeaderIsAlt(true);
  }, [])

  return (
    <>
      <IntroCollection contractId={contractId ?? ''} />

      <main className='main'>
        <div className='container'>
          <Section>
            <CollectionShowcase contractId={contractId ?? ''} />
          </Section>
        </div>
      </main>
    </>
  )
}
