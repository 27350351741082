import { nanoid } from '@reduxjs/toolkit';
import React, { ReactElement, useState } from 'react'
import styled from 'styled-components'

interface Props {
  className?: string,
  tabsTitles: Array<string>,
  tabsItems: Array<ReactElement>,
  defaultTab?: number
}

function Tabs(props: Props) {
  const [currentActiveTab, setCurrentActiveTab] = useState<number>(props.defaultTab ?? 0);

  return (
    <div className={props.className}>
      <div className='tabs-nav'>
        {
          props.tabsTitles.map((title, i) => <span key={nanoid()} onClick={() => setCurrentActiveTab(i)} className={currentActiveTab === i ? 'tab-active' : ''}>{title}</span>)
        }
      </div>

      <div className='tabs-container'>
        {
          props.tabsItems.map((tabItem, i) => (
            <div className={`tab ${currentActiveTab === i ? 'tab-active' : ''}`} key={nanoid()}>{tabItem}</div>
          ))
        }
      </div>
    </div>
  )
}

export default styled(Tabs)`
  .tabs-container {
    position: relative;
  }

  .tabs-nav {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;

    span {
      display: block;
      min-width: 120px;
      padding: 10px 20px;
      border: 2px solid ${props => props.theme.colors.primary};
      font-weight: 700;
      color: ${props => props.theme.colors.secondary};
      text-align: center;
      transition: color .15s, background .15s;
      cursor: pointer;

      &.tab-active {
        background: ${props => props.theme.colors.primary};
      }
      &:first-child {
        border-radius: 10px 0 0 10px;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
      }

      + span {
        border-left: 0;
      }
    }
  }

  .tab {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity .15s, visibility .15s;

    &.tab-active {
      position: relative;
      opacity: 1;
      visibility: visible;
    }
  }
`;