import { nanoid } from '@reduxjs/toolkit';
import React, { useContext } from 'react'
import styled from 'styled-components'
import { LanguageContext } from '../context/LanguageContext';
import { getShortenedAddress } from '../utils/Helpers';

interface Props {
  className?: string,
  nft: NFT
}

function NFTHistory(props: Props) {
  const { currentLanguage } = useContext(LanguageContext);

  return (
    <div className={props.className}>
      <h2>{currentLanguage.nftShowcase.historyTable.title}</h2>

      <div className='table'>
        {
          (() => {
            if (props.nft.history.length) {
              return (
                <table>
                  <thead>
                    <tr>
                      <th>{currentLanguage.nftShowcase.historyTable.headings.event}</th>

                      <th>{currentLanguage.nftShowcase.historyTable.headings.price}</th>

                      <th>{currentLanguage.nftShowcase.historyTable.headings.from}</th>

                      <th>{currentLanguage.nftShowcase.historyTable.headings.to}</th>

                      <th>{currentLanguage.nftShowcase.historyTable.headings.date}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      props.nft.history.slice().reverse().map(historyData => (
                        <tr key={nanoid()}>
                          <td>{historyData.event}</td>

                          <td>{historyData.price ?? ''}</td>

                          <td>{getShortenedAddress(historyData.from)}</td>

                          <td>{getShortenedAddress(historyData.to)}</td>

                          <td>
                            {
                              (() => {
                                const date = new Date(historyData.date);

                                return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                              })()
                            }
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              );
            } else {
              return <p style={{ textAlign: 'center' }}>{currentLanguage.nftShowcase.historyTable.noHistory}</p>;
            }
          })()
        }
      </div>
    </div>
  )
}

export default styled(NFTHistory)`
  flex: 0 0 100%;
  padding-top: 35px;

  h2 {
    text-align: center;
  }

  .table {
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 10px; 

    table {
      width: 100%;
      border-collapse: collapse;
    }
    tr {

      &:last-child {

        td {
          border: 0;
        }
      }
    }
    th {
      height: 36px;
      color: ${props => props.theme.colors.secondary};
    }
    td {
      height: 50px;
    }

    th,
    td {
      padding: 5px 40px;
      border-bottom: 1px solid ${props => props.theme.colors.primary};
      text-align: left;
    }
  }
`;