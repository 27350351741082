import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch } from '../app/hooks';
import { LanguageContext } from '../context/LanguageContext';

interface Props {
  className?: string,
  search?: (search: string) => void,
  category?: (category: string) => void,
  buyable?: boolean,
  isCollection?: boolean,
  volumes?: (sortBy: string) => void
}

function FiltersBar(props: Props) {
  // State variables
  const dispatch = useAppDispatch();
  let searchDebounce: ReturnType<typeof setTimeout>;
  const { currentLanguage } = useContext(LanguageContext);

  // Methods
  const handleSearchFilter = (e: React.FormEvent) => {
    e.preventDefault();
  };
  const handleSaleFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let value: boolean | undefined = e.target.value === '0' ? undefined : e.target.value === '1' ? true : false;
  };
  const handleCollectionFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let value: boolean | undefined = e.target.value === '0' ? undefined : e.target.value === '1' ? true : false;
  };
  const handleVolumeFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchDebounce);

    setTimeout(() => {
      if (props.search) {
        props.search(e.target.value);
      }
    }, 500);
  }

  return (
    <div className={props.className}>
      <form action='GET' onSubmit={e => handleSearchFilter(e)}>
        {props.volumes &&
          <div className='filter-categories form-select'>
            <select name='volumes' id='volumes' className='form-field' onChange={e => props.volumes ? props.volumes(e.currentTarget.value) : ''}>
              <option value="all">{currentLanguage.ranking.filters.time.all}</option>
              <option value="week">{currentLanguage.ranking.filters.time.week}</option>
              <option value="day">{currentLanguage.ranking.filters.time.day}</option>
            </select>
          </div>
        }

        {props.search &&
          <div className='search-field'>
            <input type='text' className='form-field' placeholder={currentLanguage.explore.filters.search} onChange={e => handleSearch(e)} />

            <button type='submit' className='search-btn'><i className='fa-solid fa-search'></i></button>
          </div>
        }

        {props.category &&
          <div className='filter-categories form-select'>
            <select name='categories' id='categories' className='form-field' onChange={e => props.category ? props.category(e.currentTarget.value) : ''}>
              <option value="">{currentLanguage.ranking.filters.category.label}</option>
              <option value='Art'>{currentLanguage.ranking.filters.category.options.art}</option>
              <option value='Music'>{currentLanguage.ranking.filters.category.options.music}</option>
              <option value='Sports'>{currentLanguage.ranking.filters.category.options.sports}</option>
              <option value='Collectibles'>{currentLanguage.ranking.filters.category.options.collectibles}</option>
              <option value='Trading Cards'>{currentLanguage.ranking.filters.category.options.cards}</option>
            </select>
          </div>
        }
        
        {props.buyable &&
          <div className='filter-categories form-select'>
            <select name='isforsale' id='isforsale' className='form-field' onChange={e => handleSaleFilter(e)}>
              <option value="0">Select...</option>
              <option value="1">Buyable</option>
              <option value="2">Non buyable</option>
            </select>
          </div>
        }

        {props.isCollection &&
          <div className='filter-categories form-select'>
            <select name='categories' id='categories' className='form-field' onChange={e => handleCollectionFilter(e)}>
              <option value="0">Select...</option>
              <option value="1">Is from collection</option>
              <option value="2">Is single</option>
            </select>
          </div>
        }        
      </form>
    </div>
  )
}

export default React.memo(styled(FiltersBar)`
  margin-bottom: 40px;

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: 20px;
  }

  form {
    display: flex;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      display: block;
    }

    > * {
      flex: 0 0 260px;

      @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
        max-width: 320px;
        margin: 0 auto;
      }

      + * {
        margin-left: 15px;

        @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
          margin: 10px auto 0;
        }
      }
    }
  }
  .search-field {
    position: relative;

    input {
      padding-right: 70px;
    }
  }
  .search-btn {
    position: absolute;
    width: 60px;
    height: 100%;
    top: 0;
    right: 0;
    border: 0;
    border-radius: 0 42px 42px 0;
    font-size: 16px;
    color: ${props => props.theme.colors.tertiary};
    text-align: center;
    background: ${props => props.theme.colors.secondary};
    appearance: none;
    cursor: pointer;
  }
`);