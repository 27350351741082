import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CollectionEditor from '../components/CollectionEditor';
import IntroAlt from '../components/IntroAlt';
import Loader from '../components/Loader';
import Section from '../components/Section';
import { AppContext } from '../context/AppContext';
import { LanguageContext } from '../context/LanguageContext';
import { UserContext } from '../context/UserContext';
import { useGetCollectionQuery } from '../features/collectionsApi';
import { useGetUserQueryState } from '../features/userApi';

export default function EditCollection() {
  const { contractId } = useParams();
  const { user } = useContext(UserContext);
  const userData = useGetUserQueryState(user?.walletId ?? skipToken);
  const collectionData = useGetCollectionQuery(contractId ?? skipToken);
  const navigate = useNavigate();
  const { setHeaderIsAlt } = useContext(AppContext);
  const { currentLanguage } = useContext(LanguageContext);

  document.title = 'Edit collection';
  
  useEffect(() => {
    setHeaderIsAlt(true);
  }, [])

  useEffect(() => {
    if (!collectionData.isLoading && user?.walletId !== collectionData.data?.ownerId) {
      navigate('/');
    }
  }, [user, collectionData.isLoading]);

  return (
    <>
      <IntroAlt title={currentLanguage.editCollection.intro.title} />

      <main className='main'>
        <div className='container'>
          <Section>
            <Loader isLoading={(collectionData.isLoading && userData.isLoading)}>
              {collectionData.data &&
                <CollectionEditor data={collectionData.data} />
              }
            </Loader>
          </Section>
        </div>
      </main>
    </>
  )
}
