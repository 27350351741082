import { WalletConnection } from 'near-api-js';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LanguageContext } from '../context/LanguageContext';
import { UserContext } from '../context/UserContext';
import { getShortenedAddress } from '../utils/Helpers';
import { getConnection } from '../utils/NEAR';
import { removeTokenFromStorage } from '../utils/Storage';
import WalletConnector from './WalletConnector';

interface Props {
  className?: string
}

// Component
function AccountToolbar(props: Props) {
  const { user, setUser } = useContext(UserContext);
  const { currentLanguage } = useContext(LanguageContext);
  
  const handleLogout = async () => {
    setUser(undefined);

    const nearConnection = await getConnection();
    const wallet = new WalletConnection(nearConnection, '');

    wallet.signOut();

    removeTokenFromStorage();
  }

  return (
    <div className={props.className}>
      {user &&
        <>
          <div className='btn'>{getShortenedAddress(user.walletId)}</div>

          <div className='account-toolbar-nav'>
            <ul>
              <li>
                <Link to='/my-collections'>{currentLanguage.profileNav.navItems.myCollections}</Link>
              </li>

              <li>
                <Link to='/my-nfts'>{currentLanguage.profileNav.navItems.myNfts}</Link>
              </li>

              <li>
                <Link to='/my-offers'>{currentLanguage.profileNav.navItems.myOffers}</Link>
              </li>

              <li>
                <Link to='/edit-profile'>{currentLanguage.profileNav.navItems.editProfile}</Link>
              </li>

              <li>
                <span onClick={handleLogout}>{currentLanguage.profileNav.navItems.logout}</span>
              </li>
            </ul>
          </div>
        </>
      }

      {!user &&
        <WalletConnector />
      }
    </div>
  )
}

// Styles
export default styled(AccountToolbar)`
  position: relative;

  &:hover {

    .account-toolbar-nav {
      transform: scale(1);
      opacity: 1;
      visibility: visible;

      @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
        visibility: unset;
      }
    }
  }

  .account-toolbar-nav {
    position: absolute;
    width: 200px;
    top: 100%;
    right: 0;
    margin-top: 10px;
    border-radius: 6px;
    box-shadow: 0 0 10px 6px rgba(0, 0, 0, .1);
    transform: scale(.8);
    transform-origin: 100% 0;
    opacity: 0;
    visibility: hidden;
    background: ${props => props.theme.colors.tertiary}; 
    transition: transform .15s, opacity .15s, visibility .15s;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      position: relative;
      top: 0;
      transform: none;
      opacity: 1;
      visibility: unset;
      box-shadow: none;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 10px;
      bottom: 100%;
      left: 0;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }
    li {

      + li {
        border-top: 1px solid ${props => props.theme.colors.secondary};

        @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
          border: 0;
        }
      }
    }
    span,
    a {
      display: block;
      padding: 15px 25px;
      font-weight: 700;
      color: ${props => props.theme.colors.secondary};
      text-decoration: none;
      cursor: pointer;
      transition: color .15s;

      @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
        padding: 8px 10px;
        text-align: center;
      }

      &:hover {
        color: ${props => props.theme.colors.quaternary};
      }
    }
  }
`;