import { nanoid } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useContext, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LanguageContext } from '../context/LanguageContext';
import { UserContext } from '../context/UserContext';
import { useGetCollectionQuery } from '../features/collectionsApi';
import { useGetNftQuery } from '../features/nftApi';
import { getShortenedAddress } from '../utils/Helpers';
import Loader from './Loader';
import Modal from './Modal';
import NFTAcceptOffer from './NFTAcceptOffer';
import NFTAuctionLister from './NFTAuctionLister';
import NFTBidder from './NFTBidder';
import NFTBuyer from './NFTBuyer';
import NFTCancelContract from './NFTCancelContract';
import NFTCancelOffer from './NFTCancelOffer';
import NFTHistory from './NFTHistory';
import NFTLister from './NFTLister';
import NFTOffer from './NFTOffer';

interface Props {
  className?: string,
  tokenId: string,
  contractId: string
}

function NFTShowcase(props: Props) {
  const nftData = useGetNftQuery({ tokenId: props.tokenId, contractId: props.contractId });
  const collectionData = useGetCollectionQuery(props.contractId ?? skipToken);
  const { user } = useContext(UserContext);
  const [listModalVisible, setListModalVisible] = useState<boolean>(false);
  const [buyModalVisible, setBuyModalVisible] = useState<boolean>(false);
  const [auctionListModalVisible, setAuctionListModalVisible] = useState<boolean>(false);
  const [bidModalVisible, setBidModalVisible] = useState<boolean>(false);
  const [offerModalVisible, setOfferModalVisible] = useState<boolean>(false);
  const [acceptOfferModalVisible, setAcceptOfferModalVisible] = useState<boolean>(false);
  const [cancelOfferModalVisible, setCancelOfferModalVisible] = useState<boolean>(false);
  const [cancelContractModalVisible, setCancelContractModalVisible] = useState<boolean>(false);
  const [currentOffer, setCurrentOffer] = useState<Offer>({ address: '', tokenId: 0, amount: 0, offerId: 0 });
  const [highestBidderAddress, setHighestBidderAddress] = useState<string>('');
  const { currentLanguage } = useContext(LanguageContext);
  
  useEffect(() => {
    if (nftData.data) {
      let highestBidder = nftData.data.auctionHistory.filter(offer => !offer.invalid).sort((a, b) => b.amount - a.amount)[0];

      setHighestBidderAddress(highestBidder ? highestBidder.address : '');
    }
  }, [nftData.data]);

  return (
    <Loader isLoading={(nftData.isLoading || collectionData.isLoading)}>
      <div className={props.className}>
        <div className='nft-image-container'>
          <div className='nft-image' style={{backgroundImage: `url(${nftData.data?.metadata.media})`}}></div>
        </div>

        <div className='nft-content'>
          <div className='nft-name'>{nftData.data?.metadata.title}</div>

          <div className='nft-data'>
            <div className='nft-creator'>
              <span className='minititle'>{currentLanguage.nftShowcase.creator}</span>

              <Link to={`/user/${collectionData.data?.ownerId}`}>{getShortenedAddress(collectionData.data?.ownerId ?? '')}</Link>
            </div>

            <div className='nft-collection'>
              <span className='minititle'>{currentLanguage.nftShowcase.collection}</span>

              <Link to={`/collection/${collectionData.data?.contractId}`}>{collectionData.data?.name}</Link>
            </div>
          </div>

          <div className='nft-owner'>
            <span className='minititle'>{currentLanguage.nftShowcase.owner}</span>

            {getShortenedAddress(nftData.data?.owner ?? '')}
          </div>

          {nftData.data && 'salePrice' in nftData.data && nftData.data.salePrice > 0 &&
            <div className='nft-price'>{currentLanguage.nftShowcase.price} <strong>{nftData.data?.salePrice}</strong></div>
          }

          {(nftData.data?.isForAuction || nftData.data?.isForSale) &&
            (() => {
              const offersData = nftData.data?.isForAuction ? nftData.data?.auctionHistory.filter(offer => !offer.invalid) : nftData.data?.offerHistory.filter(offer => !offer.invalid);

              return (
                <div className='nft-offers'>
                  <div className='nft-offers-title'>{nftData.data?.isForAuction ? currentLanguage.nftShowcase.offersTable.auctionTitle : currentLanguage.nftShowcase.offersTable.saleTitle}</div>

                  <div className='nft-offers-row titles'>
                    <div>{currentLanguage.nftShowcase.offersTable.headings.amount}</div>

                    <div>{currentLanguage.nftShowcase.offersTable.headings.from}</div>
                  </div>

                  <div className='nft-offers-body'>
                    {
                      (() => {
                        if (offersData && offersData.length) {
                          return (
                            offersData.map(data => (
                              <React.Fragment key={nanoid()}>
                                {!data.invalid && 
                                  <div className='nft-offers-row' key={nanoid()}>
                                    <div>{data.amount}</div>

                                    <div>{getShortenedAddress(data.address)}</div>

                                    {nftData.data?.owner == user?.walletId && nftData.data?.isForSale &&
                                      <div className='nft-offers-action' onClick={() => { setAcceptOfferModalVisible(true); setCurrentOffer(data as Offer) }}>{currentLanguage.nftShowcase.offersTable.acceptOffer}</div>
                                    }

                                    {user?.walletId === data.address &&
                                      <div className='nft-offers-action' onClick={() => { setCancelOfferModalVisible(true); setCurrentOffer(data as Offer) }}>{currentLanguage.nftShowcase.offersTable.cancelOffer}</div>
                                    }
                                  </div>
                                }

                                {data.invalid &&
                                  <></>
                                }
                              </React.Fragment>
                            ))
                          )
                        } else {
                          return (
                            <div className='nft-offers-row'>
                              <div>{currentLanguage.nftShowcase.offersTable.noOffers}</div>
                            </div>
                          )
                        }
                      })()
                    }
                  </div>
                </div>
              )
            })()
          }

          {nftData.data?.isForAuction &&
            <div className='nft-timer'>
              <strong>{currentLanguage.nftShowcase.remainingTime} </strong>

              <Countdown date={nftData.data?.auctionData?.end} />
            </div>
          }
          
          <div className='nft-actions'>
            {user && user.walletId === nftData.data?.owner && !nftData.data?.isForSale && !nftData.data?.isForAuction && 
              <span className='btn' onClick={e => setListModalVisible(true)}>{currentLanguage.nftShowcase.saleBtn}</span>
            }

            {user && user.walletId === nftData.data?.owner && !nftData.data?.isForSale && !nftData.data?.isForAuction && 
              <span className='btn' onClick={e => setAuctionListModalVisible(true)}>{currentLanguage.nftShowcase.auctionBtn}</span>
            }

            {user && !nftData.data?.isForAuction && 
              <>
                {user && user.walletId !== nftData.data?.owner && nftData.data?.isForSale && 
                  <>
                    <span className='btn' onClick={e => setBuyModalVisible(true)}>{currentLanguage.nftShowcase.buyBtn}</span>

                    <span className='btn' onClick={e => setOfferModalVisible(true)}>{currentLanguage.nftShowcase.offerBtn}</span>
                  </>
                }
    
                {nftData.data &&
                  <>
                    <Modal isVisible={buyModalVisible} close={() => setBuyModalVisible(false)}><NFTBuyer nft={nftData.data} /></Modal>
    
                    <Modal isVisible={listModalVisible} close={() => setListModalVisible(false)}><NFTLister nft={nftData.data} reset={!listModalVisible} /></Modal>

                    <Modal isVisible={offerModalVisible} close={() => setOfferModalVisible(false)}><NFTOffer nft={nftData.data} reset={!offerModalVisible} /></Modal>

                    <Modal isVisible={acceptOfferModalVisible} close={() => setAcceptOfferModalVisible(false)}><NFTAcceptOffer nft={nftData.data} offer={currentOffer} /></Modal>
                  </>
                }
              </>
            }

            {user && user.walletId === nftData.data?.owner && (nftData.data?.isForAuction || nftData.data?.isForSale) &&
              <>
                <span className='btn' onClick={() => setCancelContractModalVisible(true)}>{nftData.data?.isForAuction ? currentLanguage.nftShowcase.cancelAuctionBtn : currentLanguage.nftShowcase.cancelSaleBtn}</span>
              </>
            }

            {nftData.data &&
              <>
                <Modal isVisible={cancelOfferModalVisible} close={() => setCancelOfferModalVisible(false)}><NFTCancelOffer nft={nftData.data} offer={currentOffer} type={nftData.data?.isForAuction ? 'auction' : 'sale'} reset={!cancelOfferModalVisible}/></Modal>

                <Modal isVisible={cancelContractModalVisible} close={() => setCancelContractModalVisible(false)}><NFTCancelContract nft={nftData.data} type={nftData.data?.isForAuction ? 'auction' : 'sale'} reset={!cancelContractModalVisible} /></Modal>
              </>
            }

            {user && !nftData.data?.isForSale &&
              <>
                {user && user.walletId !== nftData.data?.owner && nftData.data?.isForAuction && highestBidderAddress != user.walletId &&
                  <span className='btn' onClick={() => setBidModalVisible(true)}>{currentLanguage.nftShowcase.bidBtn}</span>
                }

                {nftData.data &&
                  <>
                    <Modal isVisible={bidModalVisible} close={() => setBidModalVisible(false)}><NFTBidder nft={nftData.data} reset={!bidModalVisible}/></Modal>

                    <Modal isVisible={auctionListModalVisible} close={() => setAuctionListModalVisible(false)}><NFTAuctionLister nft={nftData.data} reset={!auctionListModalVisible}/></Modal>
                  </>
                }
              </>
            }
          </div>
        </div>
        
        {nftData.data &&
          <NFTHistory nft={nftData.data} />
        }
      </div>
    </Loader>
  )
}

export default styled(NFTShowcase)`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
    text-align: center;
  }

  .nft-timer {
    font-size: 22px;
    color: ${props => props.theme.colors.secondary};
    margin-bottom: 20px;
  }
  .nft-name {
    margin-bottom: 25px;
    font-size: 36px;
    font-weight: 700;
    color: ${props => props.theme.colors.secondary};
  }
  .minititle {
    display: block;
    font-size: 12px;
    font-weight: 700;
    color: ${props => props.theme.colors.secondary};
  }
  .nft-traits {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -10px 20px;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      justify-content: center;
    }
  }
  .nft-price {
    margin-bottom: 25px;
    font-size: 45px;

    strong {
      color: ${props => props.theme.colors.secondary};
    }
  }
  .nft-trait {
    flex: 0 0 170px;
    padding: 10px;
    margin: 0 10px 5px;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 10px;
    text-align: center;
    background: rgba(64, 63, 131, .05)
  }
  .nft-trait-type {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.colors.senary};
  }
  .nft-trait-value {
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 700;
    color: ${props => props.theme.colors.secondary};
  }
  .nft-offers {
    margin-bottom: 20px;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 10px;

    .nft-offers-title {
      display: block;
      width: 100%;
      padding: 10px 20px;
      border-bottom: 1px solid ${props => props.theme.colors.primary};
      font-weight: 700;
      color: #000;
      text-align: left;
    }
    .nft-offers-action {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      opacity: 0;
      visibility: hidden;
      color: ${props => props.theme.colors.quaternary};
      cursor: pointer;
      transition: transform .15s, opacity .15s, visibility .15s;
    }
    .nft-offers-body {
      max-height: 160px;
      overflow-y: auto;
    }
    .nft-offers-row {
      position: relative;
      display: flex;

      &:hover {

        .nft-offers-action {
          transform: translateY(-50%);
          opacity: 1;
          visibility: visible;
        }
      }
      
      &.titles {

        > div {
          border-bottom: 1px solid ${props => props.theme.colors.primary};
          font-weight: 700;
          color: #000;
        }
      }

      > div {
        flex: 0 0 50%;
        padding: 10px 20px;
        text-align: left;

        &:only-child {
          flex: 0 0 100%;
        }
      }
    }
  }
  .nft-trait-appearance {
    font-size: 13px;
  }
  .nft-image-container {
    flex: 0 0 45%; 
    border-radius: 22px;
    overflow: hidden;
    box-shadow: 0px 10px 20px rgba(24, 27, 33, 0.1);

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      max-width: 400px;
      padding: 15px;
      margin: 0 auto 25px;
    }
  }
  .nft-actions {

    .btn {

      + .btn {
        margin-left: 15px;
      }
    }
  }
  .nft-image {
    padding-top: 100%;
    background-position: center center;
    background-size: contain;
  }
  .nft-data {
    margin-bottom: 20px;

    > * {

      + * {
        margin-left: 10px;
      }
    }
  }
  .nft-content {
    flex: 0 0 55%;
    padding-left: 45px;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      padding: 0;
    }
  }
  .nft-owner {
    margin-bottom: 20px;
  }
  .nft-data {

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .nft-owner,
  .nft-creator,
  .nft-collection {
    display: inline-block;
    vertical-align: middle;
  }
`;