import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components'
import theme from '../styles/Theme';

interface Props {
  className?: string,
  title?: string,
  children: React.ReactNode
}

// Component
function Section(props: Props) {
  return (
    <section className={props.className}>
      <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true} delay={window.innerHeight - (window.innerHeight * theme.animationOffset)}>
        {props.title &&
          <header className='section-head'>
            <h2 className='title-underlined'>{props.title}</h2>
          </header>
        }

        <div className='section-body'>
          {props.children}
        </div>
      </ScrollAnimation>
    </section>
  )
}

// Styles
export default styled(Section)`
  margin-bottom: 70px;

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: 40px;
  }

  .section-head {
    margin-bottom: 30px;
    text-align: center;
  }
`;