import { WalletConnection } from 'near-api-js';
import { parseNearAmount } from 'near-api-js/lib/utils/format';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LanguageContext } from '../context/LanguageContext';
import { UserContext } from '../context/UserContext';
import { useSetNftForSaleMutation } from '../features/nftApi';
import { getConnection } from '../utils/NEAR';
import { getTokenFromStorage } from '../utils/Storage';
import Loader from './Loader';

interface Props {
  className?: string,
  nft: NFT,
  reset?: boolean
}

export default function NFTLister(props: Props) {
  const [step, setStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [price, setPrice] = useState<number>(0);
  const [completedSteps, setCompletedSteps] = useState<Array<number>>([]);
  const [setForSale] = useSetNftForSaleMutation();
  const { user } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentLanguage } = useContext(LanguageContext);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (props.reset) {
      timer = setTimeout(() => {
        setStep(1);
        setIsLoading(false);
        setPrice(0);
        setCompletedSteps([]);
      }, 320);
    }

    return () => clearTimeout(timer);
  }, [props.reset]);

  useEffect(() => {
    if (searchParams.has('errorCode') && window.localStorage.getItem('selling')) {
      searchParams.delete('errorCode');
      searchParams.delete('errorMessage');

      setSearchParams(searchParams);

      window.localStorage.removeItem('selling');
    }

    if (window.localStorage.getItem('selling') && searchParams.has('transactionHashes')) {
      const sellData = JSON.parse(window.localStorage.getItem('selling') ?? '');
      
      window.localStorage.removeItem('selling');

      setForSale({ nft: { contractId: props.nft.contractId, tokenId: props.nft.tokenId }, patch: { isForSale: true, salePrice: sellData.price, wyvernAddress: '', token: getTokenFromStorage() ?? ''}});

      setStep(2);
      setCompletedSteps([1]);
    }
  }, []);

  /**
   * Approve the marketplace
   * wallet to manage the nft
   */
  const approveMarketplace = async () => {
    if (!isLoading && price > 0 && user) {
      setIsLoading(true);

      const nearConnection = await getConnection();
      const wallet = new WalletConnection(nearConnection, '');

      window.localStorage.setItem('selling', JSON.stringify({ price }));

      await wallet.account().functionCall({ contractId: props.nft.isHosted ? (process.env.REACT_APP_NFT_HOST ?? '') : props.nft.contractId, methodName: 'nft_approve', args: { collection_id: props.nft.contractId, token_id: props.nft.tokenId.toString(), account_id: process.env.REACT_APP_NEAR_MARKET_WALLET_ID, msg: JSON.stringify({ fee: 0, price: parseNearAmount(price.toString()) }) }, attachedDeposit: '320000000000000000000', gas: '300000000000000' })
    }
  }

  return (
    <div className='nft-action-modal'>
      <div className={`step ${step === 1 ? 'is-active' : ''} ${completedSteps.indexOf(1) > -1 ? 'is-completed' : ''}`}>
        <div className='step-title'>{currentLanguage.nftLister.title}</div>

        <p>{currentLanguage.nftLister.text}</p>

        <div className='step-price'>
          <label htmlFor='price' className='form-label'>{currentLanguage.nftLister.price}</label>

          <input type='text' name='price' id='price' className='form-field' defaultValue={price} onChange={e => setPrice(parseFloat(e.currentTarget.value))} />
        </div>

        <span className='btn' onClick={approveMarketplace}>
          <Loader width={10} height={10} isLoading={isLoading}>{currentLanguage.nftLister.approveBtn}</Loader>
        </span>
      </div>

      {step === 2 &&
        <div className={`step ${step === 2 ? 'is-active' : ''}`}>
          <div className='step-title'>All done!</div>

          <p>Your NFT has been successfully listed!</p>
        </div>
      }
    </div>
  )
}