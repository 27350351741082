import { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LanguageContext } from '../context/LanguageContext';
import { UserContext } from '../context/UserContext';

interface Props {
  className?: string
}

// Component
function NavBar(props: Props) {
  const { user } = useContext(UserContext);
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);

  return (
    <nav className={props.className}>
      <ul>
        {user &&
          <>
            <li>
              <Link to='/create-collection'>{currentLanguage.navbar.createCollection}</Link>
            </li>

            <li>
              <Link to='/add-collection'>{currentLanguage.navbar.addCollection}</Link>
            </li>
          </>
        }

        <li>
          <Link to='/ranking'>{currentLanguage.navbar.ranking}</Link>
        </li>

        <li>
          <Link to='/explore'>{currentLanguage.navbar.explore}</Link>
        </li>

        <li>
          <span onClick={e => setCurrentLanguage('en')}>
            <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-gb" viewBox="0 0 640 480">
              <path fill="#012169" d="M0 0h640v480H0z" />
              <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z" />
              <path fill="#C8102E" d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z" />
              <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z" />
              <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z" />
            </svg>
          </span>

          <span onClick={e => setCurrentLanguage('de')}>
            <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-de" viewBox="0 0 640 480">
              <path fill="#ffce00" d="M0 320h640v160H0z" />
              <path d="M0 0h640v160H0z" />
              <path fill="#d00" d="M0 160h640v160H0z" />
            </svg>
          </span>
        </li>
      </ul>
    </nav>
  )
}

// Styles
export default styled(NavBar)`
  font-size: 14px;
  font-weight: 700;
  color: ${props => props.theme.colors.secondary};

  > ul {
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
    list-style-type: none;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      display: block;
      text-align: center;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;

      + span {
        margin-left: 0px;
      }
    }

    svg {
      display: inline-block;
      width: 26px;
      height: 15px;
      vertical-align: middle;
    }

    > li {
      position: relative;

      &:hover {

        > a {

          &:before {
            width: 100%;
          }
        }
        > .nav-dropdown {
          transform: scale(1);
        }
      }
      &.has-dropdown {

        > a {

          &:before {
            height: 25px;
          }
          &:after {
            content: '';
            position: relative;
            display: inline-block;
            width: 6px;
            height: 6px;
            top: -3px;
            margin-left: 7px;
            vertical-align: middle;
            border-width: 2px 2px 0 0;
            border-style: solid;
            border-color: currentColor;
            transform: rotate(135deg);
          }
        }
      }

      + li {
        margin-left: 15px;

        @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
          margin: 15px 0 0;
        }
      }

      > a {
        position: relative;
        display: block;
        color: inherit;
        text-decoration: none;

        &:before {
          content: '';
          position: absolute;
          width: 0;
          height: 2px;
          top: 100%;
          left: 0;
          border-top: 2px solid ${props => props.theme.colors.secondary};
          transition: width .3s;
        }
      }
    }
  }
  .nav-dropdown {
    position: absolute;
    width: 210px;
    top: 100%;
    left: 0;
    padding: 0;
    margin-top: 20px;
    border-radius: 6px;
    box-shadow: 2px 2px 30px 0 rgba(20, 20, 20, .1);
    list-style-type: none;
    overflow: hidden;
    transform-origin: 0 0;
    transform: scale(0);
    background: ${props => props.theme.colors.septenary};
    transition: transform .15s;

    a {
      display: block;
      padding: 11px 20px;
      color: ${props => props.theme.colors.secondary};
      text-decoration: none;
      transition: color .15s, background .15s;

      &:hover {
        color: ${props => props.theme.colors.quaternary};
        background: ${props => props.theme.colors.quaternary};
      }
    }
  }
`;