import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { useGetCollectionQuery } from '../features/collectionsApi';

interface Props {
  className?: string,
  contractId: string
}

function IntroCollection(props: Props) {
  const collectionData = useGetCollectionQuery(props.contractId);

  return (
    <ScrollAnimation animateIn='animate__fadeIn' animateOnce={true} offset={0}>
      <div className={props.className} style={{backgroundImage: `url(${collectionData.data?.bannerImage})`}}>
        <div className='intro-avatar' style={{backgroundImage: `url(${collectionData.data?.avatarImage})`}}></div>
      </div>
    </ScrollAnimation>
  )
}

export default styled(IntroCollection)`
  position: relative;
  height: 300px;
  margin-bottom: 80px; 
  background-position: center center; 
  background-size: cover;

  .intro-avatar {
    position: absolute;
    width: 120px; 
    height: 120px;
    left: 50%;
    bottom: -60px;
    margin-left: -60px;
    border: 6px solid ${props => props.theme.colors.primary};
    border-radius: 50%;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, .1);
    background-color: ${props => props.theme.colors.tertiary};
    background-position: center center;
    background-size: cover;
  }
`;