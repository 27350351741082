import { nanoid } from '@reduxjs/toolkit';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LanguageContext } from '../context/LanguageContext';

interface Props {
  className?: string,
  data: Array<Collection>
}

// Component
function CollectionTable(props: Props) {
  const getDifference = (volume: number, volumePrev: number) : JSX.Element => {
    const difference = (volume - volumePrev) / volumePrev * 100;

    return <span className={`table-stat ${difference < 0 ? 'negative' : 'positive'}` }>{difference.toFixed(2)}%</span>;
  };
  const { currentLanguage } = useContext(LanguageContext);

  return (
    <div className={props.className}>
      <table>
        <thead>
          <tr>
            <th>{currentLanguage.ranking.table.headings.collection}</th>

            <th>{currentLanguage.ranking.table.headings.volume}</th>

            <th>{currentLanguage.ranking.table.headings.daily}</th>

            <th>{currentLanguage.ranking.table.headings.weekly}</th>

            <th>{currentLanguage.ranking.table.headings.floor}</th>

            <th>{currentLanguage.ranking.table.headings.owners}</th>

            <th>{currentLanguage.ranking.table.headings.supply}</th>
          </tr>
        </thead>

        <tbody>
          {!props.data.length &&
            <tr>
              <td colSpan={7} style={{textAlign: 'center'}}>{currentLanguage.ranking.table.noResult}</td>
            </tr>
          }

          {
            props.data.map(item => {
              return (
                <tr key={nanoid()}>
                  <td data-title='Collection:' className='owner'><Link to={`/collection/${item.contractId}`}><span className='collection-image' style={{ backgroundImage: `url(${item.avatarImage})`}}></span> {item.name}</Link></td>

                  <td data-title='Volume:' className='total-volume'>{item.volumeAll}</td>

                  <td data-title='Daily:' className='daily-stats'>{item.volumeDay} {item.volumeDayPrev && <>({getDifference(item.volumeDay, item.volumeDayPrev)})</>}</td>

                  <td data-title='Weekly:' className='weekly-stats'>{item.volumeWeek} {item.volumeWeekPrev && <>({getDifference(item.volumeWeek, item.volumeWeekPrev)})</>}</td>
                  
                  <td data-title='Floor Price:'>{(item.floorPrice && item.floorPrice > 0) ? item.floorPrice : 'N/A'}</td>

                  <td data-title='Owners:'>{item.owners}</td>

                  <td data-title='Total supply:'>{item.totalSupply}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

// Styles
export default styled(CollectionTable)`
  table {
    width: 100%;
    border-collapse: collapse;
    color: ${props => props.theme.colors.senary};
    table-layout: fixed;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      display: block;
    }
  }
  th {
    width: 11.6%;
    padding: 30px 0;
    color: ${props => props.theme.colors.secondary};
    text-align: left;

    &:nth-child(1) {
      width: 30%;
    }
  }
  tbody {
    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      display: block;
    }
  }
  tr {
    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: 15px 0;

      + tr {
        border-top: 1px solid ${props => props.theme.colors.secondary};
      }
    }
  }
  td {
    height: 85px;
    padding: 10px 0;
    border-top: 1px solid ${props => props.theme.colors.secondary};
    vertical-align: middle;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      display: block;
      height: auto;
      flex: 0 0 33.3%;
      border: 0;

      &:only-child {
        flex: 0 0 100%;
      }

      &:before {
        content: attr(data-title);
        display: block;
        margin-bottom: 8px;
        font-weight: 700;
      }
    }
  }
  .collection-image {
    display: inline-block;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    border-radius: 50%;
    margin: 0 10px;
    background-position: center center;
    background-size: cover;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      margin-left: 0;
    }
  }
  a {
    display: inline-block;
    color: inherit;
    text-decoration: none;
    transition: opacity .15s;

    &:hover {
      opacity: .7;
    }
  }
  thead {
    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      display: none;
    }
  }

  .owner {
    font-weight: 700;

    a {
      display: block;
      padding-right: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      flex: 0 0 100%;
      text-align: center;
    }
  }
  .table-stat {
    display: inline-block;
    color: ${props => props.theme.colors.septenary};

    &.negative {
      color: ${props => props.theme.colors.quaternary};
    }
  }
`;