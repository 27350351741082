import { useContext, useEffect, useState } from 'react';
import CollectionTable from '../components/CollectionTable';
import FiltersBar from '../components/FiltersBar';
import IntroAlt from '../components/IntroAlt';
import Loader from '../components/Loader';
import Section from '../components/Section';
import { AppContext }  from '../context/AppContext';
import { LanguageContext } from '../context/LanguageContext';
import { useGetHotCollectionsQuery, useGetTopCollectionsQuery } from '../features/collectionsApi';

export default function Ranking() {
  const [category, setCategory] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('volumeAll');
  const collectionData = useGetTopCollectionsQuery({
    category: category === '' ? undefined : category,
    sortBy
  });
  const { setHeaderIsAlt } = useContext(AppContext);
  const { currentLanguage } = useContext(LanguageContext);

  document.title = 'Ranking';

  useEffect(() => {
    setHeaderIsAlt(true);
  }, [])
  
  return (
    <>
      <IntroAlt title={currentLanguage.ranking.intro.title} />

      <main className='main'>
        <div className='container'>
          <Section>
            <FiltersBar volumes={(sortBy: string) => setSortBy(sortBy)} category={(category: string) => setCategory(category)} />

            <Loader isLoading={(collectionData.isLoading || collectionData.isFetching)}>
              <CollectionTable data={collectionData.data ?? []} />
            </Loader>
          </Section>
        </div>
      </main>
    </>
  )
}
