import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string,
  data: any
}

function CategoryBox(props: Props) {
  return (
    <div className={props.className}>
      <Link to={props.data.link}>
        <i className={`fa-solid ${props.data.icon}`}></i> {props.data.text}
      </Link>
    </div>
  )
}

export default styled(CategoryBox)`

  a {
    display: block;
    padding: 20px;
    border-radius: 6px;
    font-weight: 700;
    color: ${props => props.theme.colors.secondary};
    text-decoration: none;
    text-align: center;
    background: ${props => props.theme.colors.quinary};
    transition: box-shadow .15s, color .15s, background .15s;

    &:hover {
      box-shadow: 5px 5px 30px ${props => props.theme.colors.primary};
      background: ${props => props.theme.colors.primary};
    }
  }
  i {
    display: block;
    margin-bottom: 15px;
    font-size: 40px;
    color: ${props => props.theme.colors.secondary};
    transition: color .15s;
  }
`;