import React, { useContext, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext } from '../context/AppContext';
import { LanguageContext } from '../context/LanguageContext';

interface Props {
  className?: string
}

function Intro(props: Props) {
  const { setHeaderIsAlt } = useContext(AppContext);
  const { currentLanguage } = useContext(LanguageContext);

  useEffect(() => {
    setHeaderIsAlt(true);

    return () => {
      setHeaderIsAlt(false);
    }
  }, []);

  return (
    <ScrollAnimation animateIn='animate__fadeIn' animateOnce={true} offset={0}>
      <div className={props.className}>
        <div className='container'>
          <div className='intro-content'>
            <div className='intro-minititle'>{currentLanguage.homepage.intro.smallTitle}</div>

            <h1>{currentLanguage.homepage.intro.title}</h1>

            <p>{currentLanguage.homepage.intro.text}</p>

            <Link to='/explore' className='btn'>{currentLanguage.homepage.intro.button}</Link>
          </div>

          <div className='intro-image'>
            <img src='assets/nft.png' alt='Alt text' width='800' height='778' />
          </div>
        </div>
      </div>
    </ScrollAnimation>
  )
}

export default React.memo(styled(Intro)`
  position: relative;
  display: flex;
  min-height: 100vh;
  align-items: center;
  z-index: 1;

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    height: 100vh;
    min-height: 600px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 1800px;
    height: 1800px;
    top: 0;
    right: 0;
    margin: -1044px -990px 0 0;
    border-radius: 50%;
    opacity: .15;
    background: ${props => props.theme.colors.septenary};
    pointer-events: none;
  }

  p {
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 1.7;
  }

  .container {
    display: flex;
    align-items: center;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      display: block;
    }
  }
  .intro-content {
    position: relative;
    max-width: 50%;
    padding-right: 30px;
    z-index: 1;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      max-width: none;
      padding: 0;
      text-align: center;
    }
  }
  .intro-image {
    position: relative;
    z-index: 1;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      display: none;
    }
  }
  .intro-minititle {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
    color: ${props => props.theme.colors.secondary};
    text-transform: uppercase;
  }
`);
