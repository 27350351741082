import { current, nanoid } from '@reduxjs/toolkit'
import { rgba } from 'polished'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { LanguageContext } from '../context/LanguageContext'
import { useGetCollectionsQuery } from '../features/collectionsApi'
import Loader from './Loader'
import Modal from './Modal'
import NFTCancelOffer from './NFTCancelOffer'

interface Props {
  className?: string,
  nfts: Array<NFT>,
  walletId: string,
  type: 'Offer' | 'Bid',
  callbackFromParent: () => void
}

function OffersTable(props: Props) {
  const [selectedNft, setSelectedNft] = useState<NFT>(props.nfts[0]);
  const [selectedOffer, setSelectedOffer] = useState<Offer>(props.nfts[0] ? props.nfts[0].offerHistory[0] : {} as Offer);
  const [cancelOfferModalVisible, setCancelOfferModalVisible] = useState<boolean>(false);
  const collectionsData = useGetCollectionsQuery({ contractId: JSON.stringify({ $in: props.nfts.map(nft => nft.contractId )}) });
  const { currentLanguage } = useContext(LanguageContext);

  /**
   * A helper function
   * to get an offer 
   * from a NFT
   * @param { NFT } nft 
   * @returns { Offer }
   */
  const getOffer = (nft: NFT) : Offer => {
    return props.type === 'Offer' ? nft.offerHistory.filter(item => item.address === props.walletId)[0] : nft.auctionHistory.filter(item => item.address === props.walletId)[0];
  }

  return (
    <Loader isLoading={collectionsData.isFetching || collectionsData.isLoading}>

      <div className={props.className}>
        <Modal isVisible={cancelOfferModalVisible} close={() => { setCancelOfferModalVisible(false); props.callbackFromParent() }}><NFTCancelOffer nft={selectedNft} offer={selectedOffer} type={props.type === 'Bid' ? 'auction' : 'sale'} reset={cancelOfferModalVisible} /></Modal>

        {
          (() => {
            if (props.nfts.length) {
              return (
                <table>
                  <thead>
                    <tr>
                      <th>{currentLanguage.myOffers.table.headings.nft}</th>

                      <th>{currentLanguage.myOffers.table.headings.collection}</th>

                      <th>{currentLanguage.myOffers.table.headings.amount}</th>

                      <th>{currentLanguage.myOffers.table.headings.actions}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      props.nfts.map(nft => (
                        <tr key={nanoid()} className={`${getOffer(nft) && getOffer(nft).invalid ? 'is-invalid' : ''}`}>
                          <td className='table-nft'>
                            <Link to={`/collection/${nft.contractId}/${nft.tokenId}`}>
                              <span style={{ backgroundImage: `url(${nft.metadata.media})` }}></span> {nft.metadata.title}
                            </Link>
                          </td>

                          <td className='table-collection'>
                            {
                              (() => {
                                if (collectionsData.data) {
                                  const collection = collectionsData.data?.filter(collection => collection.contractId === nft.contractId)[0];

                                  return <Link to={`/collection/${collection.contractId}`}>{collection.name}</Link>
                                } else {
                                  return <></>;
                                }
                              })()
                            }</td>

                          <td className='table-amount'>{
                            (() => {
                              const offerData = getOffer(nft);

                              if (offerData && 'amount' in offerData) {
                                return offerData.amount;
                              } else {
                                return 'N/A';
                              }
                            })()
                          }</td>

                          <td className='table-actions'>
                            <span onClick={() => { setSelectedNft(nft); setSelectedOffer(getOffer(nft)); setCancelOfferModalVisible(true); }}>Cancel {props.type.toLowerCase()}</span>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              )
            } else {
              return <div className='text-center'>{currentLanguage.myOffers.noOffersMessage}</div>
            }
          })()
        }
      </div>
    </Loader>
  )
}

export default styled(OffersTable)`
  table {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    border-collapse: collapse;
    color: ${props => props.theme.colors.senary};
    table-layout: fixed;
  }
  th {
    width: 11.6%;
    padding: 30px 0;
    color: ${props => props.theme.colors.secondary};
    text-align: left;

    &:nth-child(1) {
      width: 30%;
    }
  }
  td {
    height: 85px;
    padding: 10px 0;
    border-top: 1px solid ${props => props.theme.colors.secondary};
    vertical-align: middle;
  }
  a {
    display: inline-block;
    color: inherit;
    text-decoration: none;
    transition: opacity .15s;

    &:hover {
      opacity: .7;
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {

    thead {
      display: none;
    }
    tr {
      display: flex;
      padding: 15px 0;
      flex-flow: row wrap;
      align-items: center;

      > * {
        flex: 0 0 60%;
        height: auto;
        padding: 10px 0;
        border: 0;

        &:nth-child(even) {
          flex: 0 0 40%;
        }

        &:before {
          content: attr(data-title);
          display: block;
          font-weight: 700;
          margin-bottom: 5px;
        }
      }

      + tr {
        border-top: 1px solid ${props => props.theme.colors.secondary};
      }
    }

    table,
    tbody,
    td {
      display: block;
    }
  }

  .is-invalid {
    background: ${props => rgba(props.theme.colors.quaternary, .2)};
  }
  .table-nft {

    span {
      display: inline-block;
      width: 60px;
      height: 60px;
      margin-right: 10px;
      vertical-align: middle;
      background-position: center center;
      background-size: cover;
    }
  }
  .table-actions {

    span {
      color: ${props => props.theme.colors.quaternary};
      cursor: pointer;
      transition: color .15s;

      &:hover {
        color: ${props => props.theme.colors.septenary};
      }
    }
  }
  .table-collection {
    padding-right: 10px;

    a {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .table-amount {
    font-weight: 700;
  }
`;