import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLinkTwitterMutation } from '../features/collectionsApi';
import { getTokenFromStorage } from '../utils/Storage';
import { linkAccount } from '../utils/Twitter';

export default function TwitterConnector() {
  const navigate = useNavigate();
  const [linkTwitterAccount] = useLinkTwitterMutation();

  useEffect(() => {
    linkAccount().then(async (response) => {
      const contractId = window.localStorage.getItem('collection') ?? '';

      await linkTwitterAccount({ contractId, twitterId: response, token: getTokenFromStorage() ?? '' });

      navigate(`/collection/${contractId}/edit`);
    });
  }, []);

  return (
    <div></div>
  )
}
