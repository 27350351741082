import { skipToken } from '@reduxjs/toolkit/dist/query';
import { WalletConnection } from 'near-api-js';
import { parseNearAmount } from 'near-api-js/lib/utils/format';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LanguageContext } from '../context/LanguageContext';
import { NotificationContext } from '../context/NotificationContext';
import { useGetCollectionQuery } from '../features/collectionsApi';
import { useBidNftMutation } from '../features/nftApi';
import { getConnection } from '../utils/NEAR';
import Loader from './Loader';
import NFTMiniShowcase from './NFTMiniShowcase';

interface Props {
  className?: string,
  nft: NFT,
  reset?: boolean
}

export default function NFTBidder(props: Props) {
  const getCurrentHighestBid = () => {
    if (!props.nft.auctionHistory.length) {
      return props.nft.salePrice ?? 0;
    }

    const bids = props.nft.auctionHistory.slice();

    return bids.sort((a: any, b: any) => b.amount - a.amount)[0].amount;
  }
  const collectionData = useGetCollectionQuery(props.nft.contractId ?? skipToken);
  const [isBidding, setIsBidding] = useState<boolean>(false);
  const [bidAmount, setBidAmount] = useState<number>(getCurrentHighestBid() * 1.05);
  const [hasBid, setHasBid] = useState<boolean>(false);
  const [updateNft] = useBidNftMutation();
  const { currentLanguage } = useContext(LanguageContext);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (props.reset) {
      timer = setTimeout(() => {
        setIsBidding(false);
        setBidAmount(getCurrentHighestBid() * 1.05);
        setHasBid(false);
      }, 320);
    }

    return () => clearTimeout(timer);
  }, [props.reset])

  useEffect(() => {
    if (searchParams.has('errorCode') && window.localStorage.getItem('bidding')) {
      searchParams.delete('errorCode');
      searchParams.delete('errorMessage');

      setSearchParams(searchParams);

      window.localStorage.removeItem('bidding');
    }

    if (window.localStorage.getItem('bidding') && searchParams.has('transactionHashes')) {
      window.localStorage.removeItem('bidding');

      updateNft({ nft: { contractId: props.nft.contractId, tokenId: props.nft.tokenId }});
    }
  }, [])

  const handleBidding = async () => {
    const now = new Date();

    if (!isBidding && bidAmount * 1.05 > getCurrentHighestBid() * 1.05 && (props.nft.auctionData && new Date(props.nft.auctionData?.end) > now)) {
      let isError = false;

      setIsBidding(true);

      const nearConnection = await getConnection();
      const wallet = new WalletConnection(nearConnection, '');

      window.localStorage.setItem('bidding', '1');

      await wallet.account().functionCall({ contractId: process.env.REACT_APP_AUCTIONER_ID ?? '', methodName: 'bid', args: { tokenId: props.nft.tokenId.toString(), contractId: props.nft.contractId, collectionId: props.nft.isHosted ? props.nft.contractId : undefined }, attachedDeposit: parseNearAmount(bidAmount.toString()) })
    }
  }

  return (
    <div className='nft-action-modal alternative'>
      {!hasBid &&
        <>
          <div className='title'>{currentLanguage.nftBidder.title}</div>

          {collectionData.data &&
            <NFTMiniShowcase nft={props.nft} collection={collectionData.data}/>
          }

          <div className='bid-field'>
            <label htmlFor='bid-field' className='form-label'>{currentLanguage.nftBidder.bidAmount}</label>

            <input type='text' name='bid-field' id='bid-field' className='form-field' defaultValue={getCurrentHighestBid() * 1.05} onChange={e => e.currentTarget.value ? setBidAmount(parseFloat(e.currentTarget.value)) : 0} />

            <span className={`form-error ${bidAmount < (getCurrentHighestBid() * 1.049) ? 'is-active' : ''}`}>You need to bid atleast 5% more than the current highest bidder!</span>
          </div>

          <div className='actions'>
            <span className='btn' onClick={handleBidding}>
              <Loader isLoading={isBidding} width={10} height={10}>{currentLanguage.nftBidder.bidBtn}</Loader>
            </span>
          </div>
        </>
      }

      {hasBid &&
        <div className='title'>Bid placed successfully!</div>
      }
    </div>
  )
}