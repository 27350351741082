import { useContext, useEffect } from 'react';
import CategoryBox from '../components/CategoryBox';
import Hotcard from '../components/CollectionCard';
import Columns from '../components/Columns';
import FeatureBox from '../components/FeatureBox';
import Intro from '../components/Intro';
import Loader from '../components/Loader';
import NFTCard from '../components/NFTCard';
import Section from '../components/Section';
import Slider from '../components/Slider';
import Topsellers from '../components/Topsellers';
import { AppContext }  from '../context/AppContext';
import { LanguageContext } from '../context/LanguageContext';
import { useGetHotCollectionsQuery } from '../features/collectionsApi';
import { useGetNewNftsQuery } from '../features/nftApi';

export default function Homepage() {
  // Get neccesarry data
  const newNftData = useGetNewNftsQuery();
  const hotCollectionsData = useGetHotCollectionsQuery();
  const { setHeaderIsAlt } = useContext(AppContext);
  const { currentLanguage } = useContext(LanguageContext);

  document.title = 'NFT Marketplace';

  useEffect(() => {
    setHeaderIsAlt(false);
  }, [])

  return (
    <>
      <Intro />

      <main className='main'>
        <div className='container'>
          {/* <Section>
            <Columns data={currentLanguage.homepage.featureBoxes} component={FeatureBox}/>
          </Section> */}

          <Section title={currentLanguage.homepage.hotCollections.title}>
            <Loader isLoading={hotCollectionsData.isLoading}>
              {
                (() => {
                  if (hotCollectionsData.data?.length) {
                    return <Slider data={hotCollectionsData.data} component={Hotcard} sliderOptions={
                      {
                        slidesPerView: 1,
                        spaceBetween: 30,
                        breakpoints: {
                          768: {
                            slidesPerView: 2
                          },
                          1024: {
                            slidesPerView: 3
                          }
                        }
                      }
                    } />;
                  } else {
                    return <p className='text-center'>No hot collections available!</p>
                  }
                })()
              }
            </Loader>
          </Section>

          <Section title={currentLanguage.homepage.newItems.title}>
            <Loader isLoading={newNftData.isLoading}>
              {
                (() => {
                  if (newNftData.data?.length) {
                    return <Slider data={newNftData.data} component={NFTCard} sliderOptions={
                      {
                        slidesPerView: 1,
                        spaceBetween: 30,
                        breakpoints: {
                          540: {
                            slidesPerView: 2
                          },
                          768: {
                            slidesPerView: 3
                          },
                          1024: {
                            slidesPerView: 4
                          }
                        }
                      }
                    } />
                  } else {
                    return <p className='text-center'>No nfts available!</p>
                  }
                })()
              }
            </Loader>
          </Section>

          <Section title={currentLanguage.homepage.topSellers.title}>
            <Topsellers />
          </Section>

          <Section title={currentLanguage.homepage.categoriesSection.title}>
            <Columns data={currentLanguage.homepage.categoriesSection.categories} component={CategoryBox} columns={6} />
          </Section>
        </div>
      </main>
    </>
  );
}
