import { useContext, useEffect, useState } from 'react';
import CollectionCard from '../components/CollectionCard';
import Columns from '../components/Columns';
import FiltersBar from '../components/FiltersBar';
import IntroAlt from '../components/IntroAlt';
import Loader from '../components/Loader';
import Section from '../components/Section';
import { AppContext }  from '../context/AppContext';
import { LanguageContext } from '../context/LanguageContext';
import { useGetCollectionsQuery } from '../features/collectionsApi';

export default function Explore() {
  const [category, setCategory] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [collections, setCollections] = useState<Array<Collection>>([]);
  const [stopFetching, setStopFetching] = useState<boolean>(false);
  const collectionsData = useGetCollectionsQuery({
    category: category === '' ? undefined : category,
    name: name === '' ? undefined : name,
    page
  });
  const { setHeaderIsAlt } = useContext(AppContext);
  const { currentLanguage } = useContext(LanguageContext);

  document.title = 'Explore';

  useEffect(() => {
    setHeaderIsAlt(true);
  }, [])

  useEffect(() => {
    if (collectionsData.data && !collectionsData.isLoading && !collectionsData.isFetching) {
      setCollections([...collections, ...collectionsData.data]);

      setStopFetching(collectionsData.data.length === 0);
    }
  }, [collectionsData.data, page])

  const resetState = () => {
    setCollections([]);

    setPage(1);
  }

  return (
    <>
      <IntroAlt title={currentLanguage.explore.intro.title} />

      <main className='main'>
        <div className='container'>
          <Section>
            <FiltersBar search={(name: string) => { setName(name); resetState(); }} category={(category: string) => { setCategory(category); resetState(); }} />

            <Loader isLoading={collectionsData.isLoading}>
              {
                (() => {
                  if (collections.length || collectionsData.isFetching) {
                    return (
                      <>
                        <Columns columns={3} component={CollectionCard} data={collections} page={page} setPage={(data: number) => setPage(data)} shouldFetch={stopFetching ? false : !collectionsData.isFetching} />

                        <Loader isLoading={collectionsData.isFetching}><></></Loader>
                      </>
                    )
                  } else {
                    return <p className='text-center'>No collections in this category!</p>
                  }
                })()
              }
            </Loader>
          </Section>
        </div>
      </main>
    </>
  )
}
