import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useContext, useEffect, useState } from 'react'
import Columns from '../components/Columns';
import IntroAlt from '../components/IntroAlt';
import NFTCard from '../components/NFTCard';
import OffersTable from '../components/OffersTable';
import Section from '../components/Section';
import Tabs from '../components/Tabs';
import { LanguageContext } from '../context/LanguageContext';
import { UserContext } from '../context/UserContext';
import { useGetUserOffersQuery } from '../features/usersApi';

export default function MyOffers() {
  const { user } = useContext(UserContext);
  const nftData = useGetUserOffersQuery(user?.walletId ?? skipToken, { refetchOnMountOrArgChange: true });
  const [nftsForSale, setNftsForSale] = useState<Array<NFT>>([]);
  const [nftsForAuction, setNftsForAuction] = useState<Array<NFT>>([]);
  const { currentLanguage } = useContext(LanguageContext);

  document.title = 'My offers';

  useEffect(() => {
    if (nftData.data) {
      setNftsForSale(nftData.data?.filter((nft: NFT) => nft.offerHistory.length) ?? []);
      setNftsForAuction(nftData.data?.filter((nft: NFT) => nft.auctionHistory.length) ?? []);
    }
  }, [nftData.data]);

  return (
    <>
      <IntroAlt title={currentLanguage.myOffers.intro.title} />

      <main className='main'>
        <div className='container'>
          <Section>
            <Tabs tabsTitles={[currentLanguage.myOffers.tabTitles.offers, currentLanguage.myOffers.tabTitles.bids]} tabsItems={[
              <OffersTable callbackFromParent={() => nftData.refetch()} nfts={nftsForSale} type='Offer' walletId={user?.walletId ?? ''} />,
              <OffersTable callbackFromParent={() => nftData.refetch()} nfts={nftsForAuction} type='Bid' walletId={user?.walletId ?? ''} />
            ]} />
          </Section>
        </div>
      </main>
    </>
  )
}
