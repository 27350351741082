import { nanoid } from '@reduxjs/toolkit';
import axios from 'axios';
import { Account, WalletConnection } from 'near-api-js';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components'
import { LanguageContext } from '../context/LanguageContext'
import { NotificationContext } from '../context/NotificationContext';
import { UserContext } from '../context/UserContext';
import { useAddCollectionMutation, useUpdateHostedCollectionMutation } from '../features/collectionsApi';
import { getConnection, initiateNftContractDeployment } from '../utils/NEAR';
import { getTokenFromStorage } from '../utils/Storage';
import Columns from './Columns';
import Loader from './Loader';
import NFTCard from './NFTCard';
import Section from './Section';

interface Props {
  className?: string
}

function CollectionCreator(props: Props) {
  const { user } = useContext(UserContext);
  const { currentLanguage } = useContext(LanguageContext);
  const { setNotification } = useContext(NotificationContext);
  const [isUploadingImages, setIsUploadingImages] = useState<boolean>(false);
  const [addCollection] = useAddCollectionMutation();
  const [updateHostedCollection] = useUpdateHostedCollectionMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const navigate = useNavigate();
  const { contractId } = useParams();
  const [nfts, setNfts] = useState<Array<{ 
    token_id: string,
    owner_id: string,
    metadata: { 
      title: string, 
      description: string, 
      media: string 
    }}>>([]);

  useEffect(() => {
    const collectionData = JSON.parse(window.localStorage.getItem('creating') ?? '{}');

    if (searchParams.has('errorCode') && 'owner' in collectionData && 'collectionId' in collectionData) {
      searchParams.delete('errorCode');
      searchParams.delete('errorMessage');

      setSearchParams(searchParams);

      window.localStorage.removeItem('creating');
    }
    
    if ('owner' in collectionData && 'collectionId' in collectionData && searchParams.has('transactionHashes')) {
      if (contractId) {
        updateHostedCollection({ contractId, owner: collectionData.owner, token: getTokenFromStorage() ?? '' }).then(response => {
          window.localStorage.removeItem('creating');

          navigate(`/collection/${contractId}`);
        })
      } else {
        addCollection({ name: collectionData.collectionId, patch: { contractId: collectionData.collectionId, owner: collectionData.owner, isHosted: true }}).then(response => {
          window.localStorage.removeItem('creating');
  
          navigate(`/collection/${collectionData.collectionId}`);
        });
      }
    }
  }, []);

  /**
   * Read image as base64
   */
  const createNftExamples = async (el: HTMLInputElement) => {
    const promises: Array<Promise<string>> = [];

    if (el.files) {
      setIsUploadingImages(true);

      for (let i = 0; i < el.files.length; i++) {
        promises.push(new Promise(async (res, rej) => {
          const reader = new FileReader();

          if (el.files && el.files[i]) {
            const formData = new FormData();

            formData.append('file', el.files[i]);

            await axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, {
              headers: {
                'pinata_api_key': `${process.env.REACT_APP_PINATA_KEY}`,
                'pinata_secret_api_key': `${process.env.REACT_APP_PINATA_SECRET}`,
                "Content-Type": "multipart/form-data"
              }
            }).then(response => {
              res(`https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`);
            }).catch(error => {
              setNotification('An error has occurred!');

              console.error(error);
            })
          } else {
            rej('0');
          }
        }))
      }

      await Promise.all(promises).then(response => {
        setNfts(response.map((image, i) => ({
          token_id: i.toString(),
          owner_id: user?.walletId ?? '',
          metadata: {
            title: '',
            description: '',
            media: image
          }
        })))
      })
    }
  }

  /**
   * Change NFT data
   */
  const updateNft = (tokenId: string, name: string, description: string) => {
    setNfts([
      ...nfts.map(nft => {
        if (nft.token_id === tokenId) {
          nft.metadata.title = name;
          nft.metadata.description = description;
        }

        return nft;
      }),
    ])
  }

  /**
   * Start the creating process
   */
  const createCollection = async () => {
    const allowCreation = nfts.filter(nft => nft.metadata.title !== '' && nft.metadata.description !== '').length;

    if (!isCreating && allowCreation === nfts.length) {
      setIsCreating(true);

      const nearConnection = await getConnection();
      const wallet = new WalletConnection(nearConnection, '');
      const collectionId = `${user?.walletId ?? ''}-${nanoid(8).toLowerCase()}`
  
      window.localStorage.setItem('creating', JSON.stringify({ owner: user?.walletId ?? '', collectionId }));

      if (contractId) {
        await wallet.account().functionCall({ contractId: process.env.REACT_APP_NFT_HOST ?? '', methodName: 'mintTokensToCollection', args: { tokensData: nfts, collection_id: contractId } });
      } else {
        await wallet.account().functionCall({ contractId: process.env.REACT_APP_NFT_HOST ?? '', methodName: 'mintCollection', args: { tokensData: nfts, collection_id: collectionId }});
      }
    } else {
      setNotification('Please add names and descriptions to all NFTs');
    }
  }

  return (
    <Section>
      <div className={props.className}>
        <form method="POST">
          <h2>{contractId ? currentLanguage.addToCollection.title : currentLanguage.createCollection.title}</h2>

          {
            (() => {
              if (nfts.length) {
                return (
                  <>
                    <Columns 
                      data={nfts.map(nft => ({
                        ...nft,
                        ownerId: nft.owner_id,
                        tokenId: nft.token_id
                      }))}
                      callBackToParent={(tokenId: string, name: string, description: string) => { updateNft(tokenId, name, description) }}
                      columns={4}
                      component={NFTCard}
                      allowEdit={true}
                    />

                    <div className='creator-actions'>
                      <span className='btn' onClick={createCollection}>
                        <Loader isLoading={isCreating} width={10} height={10}>{contractId ? currentLanguage.addToCollection.btn : currentLanguage.createCollection.btn }</Loader>
                      </span>
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    {!isUploadingImages &&
                      <div className='form-file text-center'>
                        <div className='form-file'>
                          <span className='btn'>{currentLanguage.createCollection.stepForth.btn}</span>

                          <input type='file' name='upload-images' id='upload-images' accept='image/.png' onChange={e => createNftExamples(e.currentTarget)} multiple />
                        </div>
                      </div>
                    }

                    {isUploadingImages &&
                      <Loader isLoading={isUploadingImages}><></></Loader>
                    }
                  </>
                );
              }
            })()
          }
        </form>
      </div>
    </Section>
  )
}

export default styled(CollectionCreator)`
  margin: 0 auto;

  h2 {
    text-align: center;
  }
  
  .form-file {
    margin-bottom: 25px;
  }
  .creator-actions {
    text-align: center;
  }
`;