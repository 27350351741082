/**
 * @param {string} url
 * Copy the parameter 
 * to the user clipboard
 */
export const copyToClipboard = async (url: string) => {
  const shareData = {
    text: url
  };
  let success = false;

  await navigator.permissions.query({ name: 'clipboard-write' as PermissionName }).then(result => {
    if (result.state === 'granted' || result.state === 'prompt') {
      navigator.clipboard.writeText(shareData.text);

      success = true;
    }
  });

  return success;
}

/**
 * Get a shortened address
 * in the format 0x...0000
 * @param { string } address
 * @returns { string }
 */
export const getShortenedAddress = (address: string) => {
  return `${address.slice(0, 2)}...${address.slice(-4)}`;
}
