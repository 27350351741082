import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import IntroAlt from '../components/IntroAlt';
import Loader from '../components/Loader';
import Section from '../components/Section';
import UserInfo from '../components/UserInfo';
import { AppContext } from '../context/AppContext';
import { useGetSingleUserQuery } from '../features/usersApi';

export default function User() {
  const { setHeaderIsAlt } = useContext(AppContext);
  const { walletId } = useParams();
  const userData = useGetSingleUserQuery(walletId ?? skipToken);

  document.title = 'Viewing user';

  useEffect(() => {
    setHeaderIsAlt(true);
  }, [])

  return (
    <>
      <IntroAlt background={userData.data?.bannerImage} />

      <main className='main'>
        <div className='container'>
          <Section>
            <Loader isLoading={userData.isLoading}>
              {userData.data &&
                <UserInfo userData={userData.data} />
              }
            </Loader>
          </Section>
        </div>
      </main>
    </>
  )
}
