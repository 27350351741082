import { skipToken } from '@reduxjs/toolkit/dist/query';
import { WalletConnection } from 'near-api-js';
import { parseNearAmount } from 'near-api-js/lib/utils/format';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LanguageContext } from '../context/LanguageContext';
import { UserContext } from '../context/UserContext';
import { useGetCollectionQuery } from '../features/collectionsApi';
import { useBuyNFTMutation } from '../features/nftApi';
import { getConnection } from '../utils/NEAR';
import Loader from './Loader';
import NFTMiniShowcase from './NFTMiniShowcase';

interface Props {
  className?: string,
  nft: NFT
}

export default function NFTBuyer(props: Props) {
  const collectionData = useGetCollectionQuery(props.nft.contractId ?? skipToken);
  const [isBuying, setIsBuying] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const [updateBoughtNFT] = useBuyNFTMutation();
  const { currentLanguage } = useContext(LanguageContext);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('errorCode') && window.localStorage.getItem('buying')) {
      searchParams.delete('errorCode');
      searchParams.delete('errorMessage');

      setSearchParams(searchParams);

      window.localStorage.removeItem('buying');
    }

    if (window.localStorage.getItem('buying') && searchParams.has('transactionHashes')) {
      window.localStorage.removeItem('buying');

      updateBoughtNFT({ nft: { tokenId: props.nft.tokenId, contractId: props.nft.contractId }, patch: { owner: user?.walletId ?? '', price: props.nft.salePrice, isHosted: props.nft.isHosted, token: window.localStorage.getItem('token') ?? ''}});
    }
  }, [])

  const handleBuying = async () => {
    if (!isBuying && user) {
      let isError = false;

      setIsBuying(true);

      const nearConnection = await getConnection();
      const wallet = new WalletConnection(nearConnection, '');

      window.localStorage.setItem('buying', '1');

      await wallet.account().functionCall({ contractId: process.env.REACT_APP_NEAR_MARKET_WALLET_ID ?? '', methodName: 'initiateTokenTransfer', args: { tokenId: props.nft.tokenId.toString(), contractId: props.nft.isHosted ? (process.env.REACT_APP_NFT_HOST ?? '') : props.nft.contractId, collectionId: props.nft.isHosted ? props.nft.contractId : undefined }, attachedDeposit: parseNearAmount(props.nft.salePrice.toString()), gas: '300000000000000' })
    }
  }

  return (
    <Loader isLoading={collectionData.isLoading}>
      <div className='nft-action-modal alternative'>
        {user && user.walletId !== props.nft.owner &&
          <>
            <div className='title'>{currentLanguage.nftBuyer.title}</div>

            {collectionData.data &&
              <NFTMiniShowcase nft={props.nft} collection={collectionData.data} />
            }

            <div className='actions'>
              <span className='btn' onClick={handleBuying}>
                <Loader isLoading={isBuying} width={10} height={10}>{currentLanguage.nftBuyer.buyBtn}</Loader>
              </span>
            </div>
          </>
        }

        {user && user.walletId === props.nft.owner &&
          <div className='title'>Congratulations on your new NFT!</div>
        }
      </div>
    </Loader>
  )
}