import styled from 'styled-components';

interface Props {
  className?: string
}

function Socials(props: Props) {
  return (
    <div className={props.className}>
      <ul>
        <li>
          <a href="https://www.reddit.com/r/schweizernft/" target="_blank">
            <i className="fa-brands fa-reddit"></i>Reddit</a>
        </li>
        <li>
          <a href="https://twitter.com/schweizernft" target="_blank">
            <i className="fa-brands fa-twitter"></i>Twitter</a>
        </li>
        <li>
          <a href="https://discord.gg/R2MTND4jeJ" target="_blank">
            <i className="fa-brands fa-discord"></i>Discord</a>
        </li>
      </ul>
    </div>
  )
}

export default styled(Socials)`
  ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  li {

    + li {
      margin-left: 10px;
    }
  }
  a {
    display: flex;
    width: 34px;
    height: 34px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: ${props => props.theme.colors.secondary};
    background: ${props => props.theme.colors.quinary};
    text-decoration: none;
    transition: color .15s, background .15s;
    font-size: 0;
    line-height: 0;
    text-indent: -4000px;

    &:hover {
      background: ${props => props.theme.colors.primary};
    }
  }
  i {
    display: block;
    font-size: 16px;
    line-height: 1;
    text-indent: 0;
  }
`;