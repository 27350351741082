import { WalletConnection } from 'near-api-js';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LanguageContext } from '../context/LanguageContext';
import { NotificationContext } from '../context/NotificationContext';
import { UserContext } from '../context/UserContext';
import { useBuyNFTMutation } from '../features/nftApi';
import { getShortenedAddress } from '../utils/Helpers';
import { getConnection } from '../utils/NEAR';
import Loader from './Loader';

interface Props {
  className?: string,
  nft: NFT,
  offer: Offer
}

export default function NFTAcceptOffer(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasAccepted, setHasAccepted] = useState<boolean>(false);
  const [updateBoughtNFT] = useBuyNFTMutation();
  const { setNotification } = useContext(NotificationContext);
  const { currentLanguage } = useContext(LanguageContext);
  const { user } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const amount = parseInt(window.localStorage.getItem('accepting-offer') ?? '0');

    if (searchParams.has('errorCode') && amount) {
      searchParams.delete('errorCode');
      searchParams.delete('errorMessage');

      setSearchParams(searchParams);

      window.localStorage.removeItem('accepting-offer');
    }

    if (amount && searchParams.has('transactionHashes')) {
      window.localStorage.removeItem('accepting-offer');

      updateBoughtNFT({ nft: { tokenId: props.nft.tokenId, contractId: props.nft.contractId }, patch: { owner: user?.walletId ?? '', price: props.nft.salePrice, isHosted: props.nft.isHosted, token: window.localStorage.getItem('token') ?? '' } });
    }
  }, [])
  

  const handleAcceptOffer = async () => {
    if (!isLoading) {
      let isError = false;
      
      setIsLoading(true);

      const nearConnection = await getConnection();
      const wallet = new WalletConnection(nearConnection, '');

      window.localStorage.setItem('accepting-offer', props.offer.amount.toString());

      await wallet.account().functionCall({ contractId: process.env.REACT_APP_NEAR_MARKET_WALLET_ID ?? '', methodName: 'acceptOffer', args: { tokenId: props.nft.tokenId.toString(), contractId: props.nft.isHosted ? (process.env.REACT_APP_NFT_HOST ?? '') : props.nft.contractId, collectionId: props.nft.isHosted ? props.nft.contractId : undefined, offerAccountId: props.offer.address }, attachedDeposit: '1', gas: '300000000000000' })
    }
  }
  
  return (
    <div className='nft-action-modal alternative'>
      {!hasAccepted &&
        <>
          <div className='title'>{currentLanguage.nftAcceptOffer.title}</div>

          <div className='current-offer'>
            <div><strong>{currentLanguage.nftAcceptOffer.amount}</strong> {props.offer.amount}</div>

            <div><strong>{currentLanguage.nftAcceptOffer.from}</strong> {getShortenedAddress(props.offer.address)}</div>
          </div>

          <div className='actions'>
            <span className='btn' onClick={handleAcceptOffer}>
              <Loader isLoading={isLoading} width={10} height={10}>{currentLanguage.nftAcceptOffer.acceptBtn}</Loader>
            </span>
          </div>
        </>
      }

      {hasAccepted &&
        <div className='title'>Offer accepted successfully!</div>
      }
    </div>
  )
}
