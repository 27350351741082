import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface Props {
  className?: string,
  data?: Array<any>,
  component: React.ComponentType<any>,
  columns?: number,
  previewData?: Array<any>,
  allowEdit?: boolean,
  page?: number,
  setPage?: (data: number) => void,
  shouldFetch?: boolean,
  callBackToParent?: (tokenId: string, name: string, description: string) => void
}

// Component
function Columns(props: Props) {
  const LocalComponent: React.ComponentType<any> = props.component;
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (containerRef.current) {
      const shouldChangePage = ((window.scrollY + window.innerHeight) * 1.1) > containerRef.current.offsetTop + containerRef.current.scrollHeight;

      if (shouldChangePage && props.page && props.setPage && props.shouldFetch) {
        props.setPage(props.page + 1);
      }
    }
  }

  useEffect(() => {
    if (props.page && props.setPage) {
      window.addEventListener('scroll', handleScroll);

      return () => window.removeEventListener('scroll', handleScroll);      
    }
  }, [window.scrollY]);

  return (
    <div className={`${props.className} ${props.columns ? `columns-${props.columns}` : ''}`} ref={containerRef}>
      {
        props.data?.map(dataItem => {
          if (props.allowEdit) {
            return <LocalComponent key={nanoid()} data={dataItem} allowEdit={props.allowEdit} callbackFromParent={props.callBackToParent} />;
          } else {
            return <LocalComponent key={nanoid()} data={dataItem} />;
          }
        })
      }
    </div>
  )
}

// Styles
export default styled(Columns)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 -0.915%;

  &.columns-6 {
    > * {
      flex: 0 0 14.83%;

      @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
        flex: 0 0 45%;
      }
    }
  }
  &.columns-4 {
    > * {
      flex: 0 0 23.17%;

      @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
        flex: 0 0 45%;
      }
    }
  }

  > * {
    flex: 0 0 31.5%;
    margin: 0 0.915% 1.83%;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      flex: 0 0 100%;
    }
  }
`;