import axios from 'axios';

/**
 * @param  {string} parameterName
 * Extracts a parameter from the URL
 * This is mainly used to get the twitter tokens
 */
function findGetParameter(parameterName: string) {
  let result = null;
  let tmp = [];

  window.location.search.substr(1).split('&').forEach(function (item) {
    tmp = item.split('=');

    if (tmp[0] === parameterName) {
      result = decodeURIComponent(tmp[1]);
    }
  });

  return result;
}

/**
 * Starts the linking
 */
export const linkAccount = async () : Promise<string> => {
  const isDenied = findGetParameter('denied');
  let userId = '';

  if (!window.localStorage.getItem('oauth') || isDenied) {
    window.localStorage.removeItem('oauth');

    return '';
  }

  const oauthData = JSON.parse(window.localStorage.getItem('oauth') as string);
  const oauthVerifier = findGetParameter('oauth_verifier');

  await axios.get(`${process.env.REACT_APP_REST_API}/twitter/finish-twitter-oauth`, { params: { ...oauthData, oauthVerifier } }).then(response => {
    userId = response.data.screen_name;

    window.localStorage.removeItem('oauth');
    window.localStorage.removeItem('ethAddress');
  }).catch(err => {
    console.error(err);

    window.localStorage.removeItem('oauth');
    window.localStorage.removeItem('ethAddress');
  });

  return userId;
}

/**
 * Initiates the oauth and
 * redirects the user to twitter
 */
export const twitterOauth = async () => {
  await axios.get(`${process.env.REACT_APP_REST_API}/twitter/twitter-oauth`).then(response => {
    window.localStorage.setItem('oauth', JSON.stringify(response.data));

    window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${response.data.oauthToken}`;
  }).catch(err => console.log(err));
}