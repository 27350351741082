import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string,
  data: Collection,
  allowEdit?: boolean
}

// Component
function CollectionCard(props: Props) {
  return (
    <div className={props.className}>
      {props.allowEdit &&
        <>
          <Link to={`/collection/${props.data?.contractId}/edit`} className='card-action card-edit'><i className="fa-solid fa-pen-to-square"></i></Link>
          
          {props.data.isHosted &&
            <Link to={`/mint/${props.data?.contractId}`} className='card-action card-add'><i className="fa-solid fa-plus"></i></Link>
          }
        </>
      }

      <div className='card-image-container'>
        <div className='card-image' style={{backgroundImage: `url(${props.data.bannerImage})`}}></div>
      </div>

      <div className='card-content'>
        <Link to={`/collection/${props.data.contractId}`} />

        <div className='card-avatar' style={{backgroundImage: `url(${props.data.avatarImage})`}}></div>

        <div className='card-name h5'>{props.data.name}</div>
      </div>
    </div>
  )
}

export default styled(CollectionCard)`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  background:${props => props.theme.colors.quinary};

  &:hover {

    .card-image {
      transform: scale(1.1);
    }
  }

  .card-action {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 10px;
    right: 10px;
    border-radius: 6px;
    line-height: 30px; 
    background: #fff;
    z-index: 1;

    &.card-add {
      right: 50px;
    }

    &:hover {

      i {
        transform: scale(1.1);
      }
    }

    i {
      display: inline-block;
      vertical-align: middle;
      transition: transform .15s;
    }
  }
  .card-avatar {
    position: relative;
    width: 66px; 
    height: 66px;
    margin: -30px auto 15px;
    border: 2px solid #fff;
    border-radius: 50%;
    border: 2px solid ${props => props.theme.colors.tertiary};
    background-color: #fff;
    background-position: center center;
    background-size: cover;
  }
  .card-image-container {
    overflow: hidden;
  }
  .card-image {
    padding-top: 56%;
    background-position: center center;
    background-size: cover;
    transition: transform .15s;
  }
  .card-content {
    padding: 0 10px 30px;

    a {
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
    }
  }
`;