import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { LanguageContext } from '../context/LanguageContext';
import { useGetUserCollectionsQuery } from '../features/collectionsApi';
import { useGetNftsForUserQuery } from '../features/nftApi';
import { copyToClipboard, getShortenedAddress } from '../utils/Helpers';
import CollectionCard from './CollectionCard';
import Columns from './Columns';
import Loader from './Loader';
import NFTCard from './NFTCard';

interface Props {
  userData: User,
  className?: string
}

function UserInfo(props: Props) {
  const [pageNft, setPageNft] = useState<number>(1);
  const [nftData, setNftData] = useState<Array<NFT>>([]);
  const [stopFetchingNft, setStopFetchingNft] = useState<boolean>(false);
  const [pageCollections, setPageCollections] = useState<number>(1);
  const [collections, setCollections] = useState<Array<Collection>>([]);
  const [stopFetchingCollections, setStopFetchingCollections] = useState<boolean>(false);
  const userNftsData = useGetNftsForUserQuery({ walletId: props.userData.walletId, page: pageNft });
  const collectionsData = useGetUserCollectionsQuery({ walletId: props.userData.walletId, page: pageCollections });
  const { currentLanguage } = useContext(LanguageContext);

  useEffect(() => {
    if (userNftsData.data && !userNftsData.isLoading && !userNftsData.isFetching) {
      setNftData([...nftData, ...userNftsData.data]);

      setStopFetchingNft(userNftsData.data.length === 0);
    }
  }, [userNftsData.data, pageNft])

  useEffect(() => {
    if (collectionsData.data && !collectionsData.isLoading && !collectionsData.isFetching) {
      setCollections([...collections, ...collectionsData.data]);

      setStopFetchingCollections(collectionsData.data.length === 0);
    }
  }, [collectionsData.data, pageCollections])

  return (
    <div className={props.className}>
      <div className='user-meta'>
        <div className='user-avatar' style={{backgroundImage: `url(${props.userData.avatarImage})`}}></div>

        <div className='user-data'>
          <div className='user-name'>{props.userData.username ?? `${getShortenedAddress(props.userData.walletId)}`}</div>

          <div className='user-address' onClick={e => copyToClipboard(props.userData.walletId)}>{props.userData.walletId.slice(0, 15)}...</div>
        </div>
      </div>

      <div className='user-stuff'>
        <h2>{currentLanguage.user.collectionsTitle}</h2>

        <Loader isLoading={collectionsData.isLoading}>
          {
            (() => {
              if (collections.length || collectionsData.isFetching) {
                return (
                  <>
                    <Columns data={collections} component={CollectionCard} columns={3} page={pageCollections} setPage={(data: number) => setPageCollections(data)} shouldFetch={stopFetchingCollections ? false : !collectionsData.isFetching} />

                    <Loader isLoading={collectionsData.isFetching}><></></Loader>
                  </>
                )
              } else {
                return <p className='text-center'>{currentLanguage.user.noCollections}</p>
              }
            })()
          }
        </Loader>
      </div>

      <div className='user-stuff'>
        <h2>{currentLanguage.user.nftsTitle}</h2>

        <Loader isLoading={userNftsData.isLoading}>
          {
            (() => {
              if (nftData.length || userNftsData.isFetching) {
                return (
                  <>
                    <Columns data={nftData} component={NFTCard} columns={4} page={pageNft} setPage={(data: number) => setPageNft(data)} shouldFetch={stopFetchingNft ? false : !userNftsData.isFetching} />

                    <Loader isLoading={userNftsData.isFetching}><></></Loader>
                  </>
                )
              } else {
                return <p className='text-center'>{currentLanguage.user.noNfts}</p>
              }
            })()
          }
        </Loader>
      </div>
    </div>
  )
}

export default styled(UserInfo)`
  .user-stuff {
    padding-top: 15px;

    h2 {
      text-align: center; 
    }
  }
  .user-meta {
    display: flex;
    align-items: center;
  }
  .user-avatar {
    flex: 0 0 200px;
    height: 200px;
    margin-right: 25px; 
    border-radius: 50%;
    background-position: center center;
    background-size: cover;
  }
  .user-name {
    margin-bottom: 8px;
    font-size: 22px;
    font-weight: 700;
    color: ${props => props.theme.colors.secondary};
  }
  .user-address {
    position: relative;
    cursor: pointer;

    &:after {
      content: ' Copy';
      font-weight: 700;
      color: ${props => props.theme.colors.secondary};
    }
  }
`;