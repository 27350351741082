import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useState } from 'react';
import Columns from '../components/Columns';
import IntroAlt from '../components/IntroAlt';
import Loader from '../components/Loader';
import NFTCard from '../components/NFTCard';
import Section from '../components/Section';
import { AppContext } from '../context/AppContext';
import { LanguageContext } from '../context/LanguageContext';
import { UserContext } from '../context/UserContext';
import { useGetNftsForUserQuery } from '../features/nftApi';
import { getTokenFromStorage } from '../utils/Storage';

export default function MyNFTs() {
  const { setHeaderIsAlt } = useContext(AppContext);
  const { user } = useContext(UserContext);
  const [page, setPage] = useState<number>(1);
  const [nftData, setNftData] = useState<Array<NFT>>([]);
  const [stopFetching, setStopFetching] = useState<boolean>(false);
  const userNftsData = useGetNftsForUserQuery({ walletId: user?.walletId ?? '', page });
  const { currentLanguage } = useContext(LanguageContext);

  document.title = 'My NFTs';

  useEffect(() => {
    setHeaderIsAlt(true);
  }, [])

  useEffect(() => {
    if (userNftsData.data && !userNftsData.isLoading && !userNftsData.isFetching) {
      setNftData([...nftData, ...userNftsData.data]);

      setStopFetching(userNftsData.data.length === 0);
    }
  }, [userNftsData.data, page])

  return (
    <>
      <IntroAlt title={currentLanguage.myNfts.intro.title} />

      <main className='main'>
        <div className='container'>
          <Section>
            <Loader isLoading={userNftsData.isLoading}>
              {
                (() => {
                  if (nftData.length || userNftsData.isFetching) {
                    return (
                      <>
                        <Columns data={nftData} component={NFTCard} columns={4} page={page} setPage={(data: number) => setPage(data)} shouldFetch={stopFetching ? false : !userNftsData.isFetching} />

                        <Loader isLoading={userNftsData.isFetching}><></></Loader>
                      </>
                    )
                  } else {
                    return <p className='text-center'>{currentLanguage.myNfts.noNFTs}</p>
                  }
                })()
              }
            </Loader>
          </Section>
        </div>
      </main>
    </>
  )
}
