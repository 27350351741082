// Slice for user
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const collectionsApi = createApi({
  reducerPath: 'collectionsApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_REST_API ?? '' }),
  tagTypes: ['Collections'],
  endpoints: builder => ({
    getHotCollections: builder.query<Array<Collection>, void>({
      query: () => '/collections/hot',
      providesTags: ['Collections']
    }),
    getUserCollections: builder.query<Array<Collection>, { walletId: string, page?: number }>({
      query : (args) => ({ url: `/collections/user/${args.walletId}`, params: args }),
      providesTags: ['Collections']
    }),
    getCollections: builder.query<Array<Collection>, { name?: string, category?: string, page?: number, contractId?: string } | undefined>({
      query: (args) => ({ url: '/collections', params: args }),
      providesTags: ['Collections']
    }),
    getTopCollections: builder.query<Array<Collection>, { sortBy: string, category?: string}>({
      query: (args) => ({ url: '/collections', params: args }),
      providesTags: ['Collections']
    }),
    getCollection: builder.query<Collection, string>({
      query: (contractId: string) => `/collections/${contractId}`,
      providesTags: ['Collections']
    }),
    addCollection: builder.mutation<Collection, { name: string, patch: { contractId: string, owner: string, isHosted?: boolean }}>({
      query: ({ name, patch }) => ({
        url: `/collections/${name}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: ['Collections']
    }),
    updateHostedCollection: builder.mutation<boolean, { contractId: string, owner: string, token: string }>({
      query: (args) => ({
        url: `/collections/collection/update`,
        method: 'PATCH',
        body: args
      })
    }),
    linkTwitter: builder.mutation<Collection, { twitterId: string, contractId: string, token: string }>({
      query: (args) => ({
        url: `/collections/twitter`,
        method: 'PATCH',
        body: args
      }),
      invalidatesTags: ['Collections']
    }),
    updateCollection: builder.mutation<Collection, { 
      name: string,
      description: string,
      avatarImage: string,
      bannerImage: string,
      category: string,
      contractId: string,
      website?: string,
      discord?: string,
      instagram?: string,
      token: string
    }>({
      query: (args) => ({
        url: `/collections/update/${args.contractId}`,
        method: 'PATCH',
        body: args
      }),
      invalidatesTags: ['Collections']
    })
  })
});

/**
 * Hooks export
 */
export const { useGetCollectionQuery, useGetHotCollectionsQuery, useGetUserCollectionsQuery, useGetCollectionsQuery, useGetTopCollectionsQuery, useAddCollectionMutation, useUpdateCollectionMutation, useLinkTwitterMutation, useUpdateHostedCollectionMutation } = collectionsApi;

export default collectionsApi;