import { createContext, Dispatch, SetStateAction, useState } from 'react';

interface UserContextInterface {
  user?: User,
  setUser: Dispatch<SetStateAction<User | undefined>>
}

interface Props {
  children: Array<React.ReactChild> | React.ReactChild
}

export const UserContext = createContext<UserContextInterface>({} as UserContextInterface);

function UserContextProvider(props: Props) {
  const [user, setUser] = useState<User>();

  return <UserContext.Provider value={{
    user,
    setUser
  }}>{props.children}</UserContext.Provider>
}

export default UserContextProvider;