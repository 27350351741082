import React, { useContext, useEffect } from 'react'
import CollectionCreator from '../components/CollectionCreator';
import IntroAlt from '../components/IntroAlt';
import Section from '../components/Section';
import { AppContext } from '../context/AppContext';
import { LanguageContext } from '../context/LanguageContext';

export default function AddToCollection() {
  const { setHeaderIsAlt } = useContext(AppContext);
  const { currentLanguage } = useContext(LanguageContext);

  document.title = 'Mint NFTs';

  useEffect(() => {
    setHeaderIsAlt(true);
  }, [])

  return (
    <>
      <IntroAlt title={currentLanguage.addToCollection.intro.title} />

      <main className='main'>
        <div className='container'>
          <Section>
            <CollectionCreator />
          </Section>
        </div>
      </main>
    </>
  )
}
