import { nanoid } from '@reduxjs/toolkit';
import { WalletConnection } from 'near-api-js';
import { parseNearAmount } from 'near-api-js/lib/utils/format';
import { useContext, useEffect, useState } from 'react';
import { useSetNftForAuctionMutation } from '../features/nftApi';
import { getConnection } from '../utils/NEAR';
import { getTokenFromStorage } from '../utils/Storage';
import { useSearchParams } from 'react-router-dom';
import Loader from './Loader';
import { LanguageContext } from '../context/LanguageContext';

interface Props {
  className?: string,
  nft: NFT,
  reset?: boolean
}

export default function NFTAuctionLister(props: Props) {
  const [step, setStep] = useState<number>(1);
  const [completedSteps, setCompletedSteps] = useState<Array<number>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [price, setPrice] = useState<number>(0);
  const [date, setDate] = useState<Date>(new Date());
  const [time, setTime] = useState<string>();
  const [setForAuction] = useSetNftForAuctionMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentLanguage } = useContext(LanguageContext);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (props.reset) {
      timer = setTimeout(() => {
        setStep(1);
        setCompletedSteps([]);
        setIsLoading(false);
        setPrice(0);
        setDate(new Date());
        setTime(undefined);
      }, 320);
    }

    return () => clearTimeout(timer);
  }, [props.reset])

  useEffect(() => {
    if (time) {
      let dateValue = date;

      dateValue.setHours(parseInt(time?.split(':')[0]));
      dateValue.setMinutes(parseInt(time?.split(':')[1]));

      setDate(dateValue);
    }
  }, [time, date]);

  useEffect(() => {
    const data = JSON.parse(window.localStorage.getItem('setting-auction') ?? '{}');

    if (searchParams.has('errorCode') && 'price' in data) {
      searchParams.delete('errorCode');
      searchParams.delete('errorMessage');
      
      setSearchParams(searchParams);

      window.localStorage.removeItem('setting-auction');
    }

    if ('price' in data && searchParams.has('transactionHashes')) {
      window.localStorage.removeItem('setting-auction');

      setForAuction({ nft: { contractId: props.nft.contractId, tokenId: props.nft.tokenId }, patch: { isForAuction: true, salePrice: data.price, auctionAddress: process.env.REACT_APP_AUCTIONER_ID ?? '', auctionData: { end: new Date(data.date) }, token: getTokenFromStorage() ?? '' }})
    }
  }, [])

  const handleAuctionContractDeploy = async () => {
    if (!isLoading && price > 0 && time && time !== '-') {
      setIsLoading(true);

      const nearConnection = await getConnection();
      const wallet = new WalletConnection(nearConnection, '');

      window.localStorage.setItem('setting-auction', JSON.stringify({
        price,
        date
      }))

      await wallet.account().functionCall({ contractId: props.nft.isHosted ? (process.env.REACT_APP_NFT_HOST ?? '') : props.nft.contractId, methodName: 'nft_approve', args: { collection_id: props.nft.contractId, token_id: props.nft.tokenId.toString(), account_id: process.env.REACT_APP_AUCTIONER_ID, msg: JSON.stringify({ fee: 0, price: parseNearAmount(price.toString()), end: date }) }, attachedDeposit: '320000000000000000000', gas: '300000000000000' })
    }
  }

  return (
    <div className='nft-action-modal'>
      <div className={`step ${step === 1 ? 'is-active' : ''} ${completedSteps.indexOf(1) > -1 ? 'is-completed' : ''}`}>
        <div className='step-title'>{currentLanguage.nftAuctionLister.title}</div>

        <p>{currentLanguage.nftAuctionLister.text}</p>

        <div className='step-price'>
          <label htmlFor='price' className='form-label'>{currentLanguage.nftAuctionLister.price}</label>

          <input type='text' name='price' id='price' className='form-field' defaultValue={price} onChange={e => setPrice(parseFloat(e.currentTarget.value))} />
        </div>

        <div className='step-auction-end'>
          {
            (() => {
              const currentDate = new Date();
              let maxEndDate;

              if (currentDate.getHours() >= 18) {
                currentDate.setDate(currentDate.getDate() + 1);
              }

              maxEndDate = new Date(currentDate);
              maxEndDate.setDate(maxEndDate.getDate() + 7);
              
              return (
                <>
                  <input type='date' name='date-end' id='date-end' className='form-field' min={`${currentDate.getFullYear()}-${currentDate.getMonth() + 1 > 9 ? currentDate.getMonth() + 1 : `0${currentDate.getMonth() + 1}`}-${currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate()}`} max={`${maxEndDate.getFullYear()}-${maxEndDate.getMonth() + 1 > 9 ? maxEndDate.getMonth() + 1 : `0${maxEndDate.getMonth() + 1}`}-${maxEndDate.getDate() < 10 ? `0${maxEndDate.getDate()}` : maxEndDate.getDate()}`} onChange={e => setDate(new Date(e.currentTarget.value))} />

                  <span>-</span>

                  <div className='form-select'>
                    <select name='time-end' id='time-end' className='form-field' onChange={e => setTime(e.currentTarget.value)} value={time}>
                      <option value='-'>-</option>

                      {
                        (() => {
                          let hour = currentDate.getDate() === date.getDate() ? currentDate.getHours() + 6 >= 24 ? currentDate.getHours() - 18 : currentDate.getHours() + 6 : 0;
                          let jsxTemplate: Array<JSX.Element> = [];
                          
                          while(hour < 24) {
                            const minutes = (function*() {
                              yield '00';
                              yield '15';
                              yield '30';
                              yield '45';
                            })();

                            for (let i = 0; i < 4; i++) {
                              const time = `${hour < 10 ? `0${hour}` : hour}:${minutes.next().value}`;

                              jsxTemplate.push(<option key={nanoid()} value={time}>{time}</option>)
                            }

                            hour++;
                          }

                          return jsxTemplate;
                        })()
                      }
                    </select>
                  </div>
                </>
              );
            })()
          }
        </div>

        <span className='btn' onClick={handleAuctionContractDeploy}>
          <Loader width={10} height={10} isLoading={isLoading}>{currentLanguage.nftAuctionLister.startBtn}</Loader>
        </span>
      </div>
    </div>
  )
}
