import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import IntroAlt from '../components/IntroAlt';
import Section from '../components/Section';
import TermsRenderer from '../components/TermsRenderer';
import { AppContext } from '../context/AppContext';
import { LanguageContext } from '../context/LanguageContext';

export default function NFT() {
  const { setHeaderIsAlt } = useContext(AppContext);
  const { currentLanguage } = useContext(LanguageContext);

  document.title = 'Terms of Service';

  useEffect(() => {
    setHeaderIsAlt(true);
  }, [])

  return (
    <>
      <IntroAlt title={currentLanguage.termsOfService.intro.title} />

      <main className='main'>
        <div className='container'>
          <Section>
            <TermsRenderer data={currentLanguage.termsOfService.data} />
          </Section>
        </div>
      </main>
    </>
  )
}
