import { WalletConnection } from 'near-api-js';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LanguageContext } from '../context/LanguageContext';
import { NotificationContext } from '../context/NotificationContext';
import { useBidNftMutation, useUpdateOffersMutation } from '../features/nftApi';
import { getConnection } from '../utils/NEAR';
import Loader from './Loader';

interface Props {
  className?: string,
  nft: NFT,
  offer: Offer,
  type: string,
  reset?: boolean
}

export default function NFTCancelOffer(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasCancelled, setHasCancelled] = useState<boolean>(false);
  const [updateOffers] = useUpdateOffersMutation();
  const [updateAuctionOffers] = useBidNftMutation();
  const { setNotification } = useContext(NotificationContext);
  const { currentLanguage } = useContext(LanguageContext);
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Reset popup
   * when the target
   * NFT changes
   */
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (props.reset) {
      timer = setTimeout(() => {
        setIsLoading(false);
        setHasCancelled(false);
      }, 320);
    }

    return () => clearTimeout(timer);
  }, [props.reset]);

  useEffect(() => {
    const nft = JSON.parse(window.localStorage.getItem('cancelling-offer') ?? '{}');

    if (searchParams.has('errorCode') && nft && 'tokenId' in nft) {
      searchParams.delete('errorCode');
      searchParams.delete('errorMessage');

      setSearchParams(searchParams);

      window.localStorage.removeItem('cancelling-offer');
    }

    if (nft && 'tokenId' in nft && searchParams.has('transactionHashes')) {
      window.localStorage.removeItem('cancelling-offer');

      if (nft.type === 'sale') {
        updateOffers({ nft: { contractId: nft.contractId, tokenId: nft.tokenId } });
      } else {
        updateAuctionOffers({ nft: { contractId: nft.contractId, tokenId: nft.tokenId } });
      }
    }
  }, [])
  

  const handleCancelOffer = async () => {
    if (!isLoading) {
      setIsLoading(true);

      const nearConnection = await getConnection();
      const wallet = new WalletConnection(nearConnection, '');

      window.localStorage.setItem('cancelling-offer', JSON.stringify({ ...props.nft, type: 'sale' }));

      if (props.type === 'sale') {
        await wallet.account().functionCall({ contractId: process.env.REACT_APP_NEAR_MARKET_WALLET_ID ?? '', methodName: 'cancelOffer', args: { tokenId: props.nft.tokenId.toString(), contractId: props.nft.isHosted ? (process.env.REACT_APP_NFT_HOST ?? '') : props.nft.contractId, collectionId: props.nft.isHosted ? props.nft.contractId : undefined }, attachedDeposit: '1' })
      } else {
        await wallet.account().functionCall({ contractId: process.env.REACT_APP_AUCTIONER_ID ?? '', methodName: 'cancelBid', args: { tokenId: props.nft.tokenId.toString(), contractId: props.nft.isHosted ? (process.env.REACT_APP_NFT_HOST ?? '') : props.nft.contractId, collectionId: props.nft.isHosted ? props.nft.contractId : undefined }, attachedDeposit: '1' })
      }
    }
  }

  return (
    <div className='nft-action-modal alternative'>
      {!hasCancelled &&
        <>
          <div className='title'>{currentLanguage.nftCancelOffer.title}</div>

          <p className='text-center'>{currentLanguage.nftCancelOffer.text}</p>

          <div className='actions'>
            <span className='btn' onClick={handleCancelOffer}>
              <Loader isLoading={isLoading} width={10} height={10}>{currentLanguage.nftCancelOffer.cancelBtn}</Loader>
            </span>
          </div>
        </>
      }

      {hasCancelled &&
        <div className='title'>Offer cancelled successfully!</div>
      }
    </div>
  )
}