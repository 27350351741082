import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { NotificationContext } from '../context/NotificationContext';
import { rgba } from 'polished';

interface Props {
  className?: string
}

function Notification(props: Props) {
  const { notification, setNotification } = useContext(NotificationContext);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  let notificationRemovalTimer : ReturnType<typeof setTimeout>;

  useEffect(() => {
    if (notification) {
      clearTimeout(notificationRemovalTimer);

      setIsVisible(true);

      notificationRemovalTimer = setTimeout(() => {
        setIsVisible(false);

        setTimeout(() => {
          setNotification(undefined);
        }, 200);
      }, 5000);
    }
  }, [notification]);
  
  return (
    <div className={`${props.className} ${isVisible ? 'is-visible' : ''}`}>{ notification }</div>
  )
}

export default styled(Notification)`
  position: fixed;
  max-width: 520px;
  padding: 15px;
  bottom: 10px;
  right: 0;
  box-shadow: 0 0 4px 2px ${props => rgba(props.theme.colors.secondary, .1)};
  border: 1px solid ${props => props.theme.colors.primary};
  border-right: 0;
  border-radius: 10px 0 0 10px;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  color: ${props => props.theme.colors.tertiary};
  background: ${props => props.theme.colors.primary};
  z-index: 10;
  transition: transform .15s, opacity .15s, visibility .15s;

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 90vw;
  }

  &.is-visible {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
`;