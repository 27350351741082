import React, { useContext } from 'react'
import { Oval } from 'react-loader-spinner';
import styled, { ThemeContext } from 'styled-components';

interface Props {
  className?: string,
  width?: number,
  height?: number,
  isLoading: boolean,
  children: React.ReactNode
}

function Loader(props: Props) {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      {props.isLoading &&
        <div className={props.className}>
          <Oval color={themeContext.colors.primary} width={props.width ?? 60} height={props.height ?? 60} />
        </div>
      }

      {!props.isLoading &&
        props.children
      }
    </>
  )
}

export default styled(Loader)`
  display: table;
  margin: 0 auto;
  
  circle {
    stroke: ${props => props.theme.colors.quaternary};
  }

  > div {
    justify-content: center;
  }
`;
