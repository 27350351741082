import React, { useEffect } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished';

interface Props {
  className?: string,
  children: React.ReactNode,
  isVisible: boolean,
  close: () => void
}

function Modal(props: Props) {
  return (
    <div className={`${props.className} ${props.isVisible ? 'is-visible' : ''}`}>
      <div className='modal-close' onClick={props.close}>Close</div>

      <div className='modal-content'>
        {props.children}
      </div>
    </div>
  )
}

export default styled(Modal)`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  opacity: 0; 
  visibility: hidden;
  overflow-y: auto;
  background: ${props => rgba(props.theme.colors.secondary, .8)};
  z-index: 6;
  transition: opacity .15s, visibility .15s;

  &.is-visible {
    opacity: 1;
    visibility: visible;

    .modal-content {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
    }
  }

  .modal-close {
    position: absolute; 
    width: 40px;
    height: 40px;
    top: 15px;
    right: 15px;
    font-size: 0;
    cursor: pointer;

    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      top: 50%; 
      right: 0;
      margin-top: -1px;
      background: ${props => props.theme.colors.tertiary};
    }
  }
  .modal-content {
    width: 420px;
    margin: 0 10px;
    border-radius: 10px;
    overflow: hidden;
    transform: scale(.8);
    opacity: 0;
    visibility: hidden;
    background: ${props => props.theme.colors.tertiary};
    transition: transform .15s, opacity .15s, visibility .15s;
  }
`;