import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { LanguageContext } from '../context/LanguageContext';
import { NotificationContext } from '../context/NotificationContext';
import { UserContext } from '../context/UserContext'
import { useUpdateAvatarMutation, useUpdateBannerMutation } from '../features/userApi';
import Loader from './Loader';

interface Props {
  className?: string
}

function ProfileImagesEditor(props: Props) {
  const { user, setUser } = useContext(UserContext);
  const [avatarImage, setAvatarImage] = useState<string>(user?.avatarImage ?? '');
  const [bannerImage, setBannerImage] = useState<string>(user?.bannerImage ?? '');
  const [updateAvatar, avatarResult] = useUpdateAvatarMutation();
  const [updateBanner, bannerResult] = useUpdateBannerMutation();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const { setNotification } = useContext(NotificationContext);
  const { currentLanguage } = useContext(LanguageContext);

  /**
   * Read image as base64
   */
  const imageToBase64 = (el: HTMLInputElement, set: (image: string) => void) => {
    const reader = new FileReader();

    if (el.files) {
      reader.readAsDataURL(el.files[0]);

      reader.onload = file => {
        set(reader.result as string);
      }

      reader.onerror = err => {
        setNotification(currentLanguage.editProfile.errorNotification);

        console.error(err);
      }
    }
  }

  /**
   * Handle uploading and
   * update of collection
   */
  const handleImageUpdate = (type: 'avatar' | 'banner', image: string, mutator: MutationTrigger<MutationDefinition<{name: string; patch: { avatarImage: string; } | { bannerImage: string }}, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, 'User', User, 'userApi'>>) => {
    mutator({ name: user?.walletId as string, patch: type === 'avatar' ? { avatarImage: image } : { bannerImage: image }}).then(response => {
      if ('data' in response) {
        setUser(response.data);

        setNotification(currentLanguage.editProfile.imageSuccessNotification);
      } else {
        setNotification(currentLanguage.editProfile.errorNotification);  
      }
    }).catch(err => {
      setNotification(currentLanguage.editProfile.errorNotification);

      console.error(err)
    });
  }

  return (
    <div className={props.className}>
      {user && 
        <form action='PATCH'>
          <div className='form-row'>
            <label htmlFor='avatar' className='form-label'>{currentLanguage.editProfile.avatar}</label>

            <div className='image-placeholder' style={{backgroundImage: `url(${avatarImage})`}}></div>

            <div className='form-file'>
              <span className='btn'>{currentLanguage.editProfile.selectFile}</span>

              <input type='file' name='avatar' id='avatar' accept='image/.png' onChange={e => imageToBase64(e.currentTarget, setAvatarImage)}/>
            </div>

            <span className='btn' onClick={() => handleImageUpdate('avatar', avatarImage, updateAvatar)}>
              <Loader isLoading={avatarResult.isLoading} width={10} height={10}>{currentLanguage.editProfile.avatarButton}</Loader>
            </span>
          </div>

          <div className='form-row'>
            <label htmlFor='banner' className='form-label'>{currentLanguage.editProfile.banner}</label>

            <div className='image-placeholder banner' style={{ backgroundImage: `url(${bannerImage})` }}></div>

            <div className='form-file'>
              <span className='btn'>{currentLanguage.editProfile.selectFile}</span>

              <input type='file' name='banner' id='banner' accept='image/.png' onChange={e => imageToBase64(e.currentTarget, setBannerImage)} />
            </div>

            <span className='btn' onClick={() => handleImageUpdate('banner', bannerImage, updateBanner)}>
              <Loader isLoading={bannerResult.isLoading} width={10} height={10}>{currentLanguage.editProfile.bannerButton}</Loader>
            </span>
          </div>
        </form>
      }
    </div>
  )
}

export default styled(ProfileImagesEditor)`
  .image-placeholder {
    width: 150px;
    height: 150px;
    margin: 15px 0;
    border-radius: 50%;
    background-position: center center;
    background-size: cover;

    &.banner {
      width: 310px;
      height: 170px;
      border-radius: 10px;

      @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
        width: 100%;
        padding-top: 60%;
      }
    }
  }
  .form-file {
    margin-bottom: 10px;
  }
`;