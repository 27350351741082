import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CardOwner from './CardOwner';

interface Props {
  className?: string,
  data: NFT,
  allowEdit?: boolean,
  callbackFromParent?: (tokenId: string, name: string, description: string) => void
}

// Component
function NFTCard(props: Props) {
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [name, setName] = useState<string>(props.data.metadata.title ?? '');
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);

  const updateData = () => {
    if (props.callbackFromParent && nameRef.current && descriptionRef.current) {
      props.callbackFromParent(props.data.tokenId.toString(), nameRef.current.value, descriptionRef.current.value);
    }
  }

  // Full view
  return (
    <div className={props.className}>
      <div className='card-image' style={{backgroundImage: `url(${props.data.metadata.media})`}}></div>

      {props.allowEdit &&
        <>
          <div className='card-content'>
            <div className='form-row'>
              <label htmlFor='name' className='form-label'>Name:</label>

              <input type='text' name='name' id='name' className='form-field' defaultValue={name} onBlur={e => updateData()} ref={nameRef} placeholder={`#${props.data.tokenId}`} />
            </div>
            
            <div className='form-row'>
              <label htmlFor='description' className='form-label'>Description:</label>

            <textarea name='description' id='description' className='form-field' defaultValue={props.data.metadata.description} onBlur={e => updateData()} ref={descriptionRef} placeholder={`Description #${props.data.tokenId}`} />
            </div>
          </div>
        </>
      }

      {!props.allowEdit &&
        <>
          <div className='card-content'>
            <div className='name h5'>{props.data.metadata.title}</div>
          </div>

          <Link to={`/collection/${props.data.contractId}/${props.data.tokenId}`} />
        </>
      }
    </div>
  )
}

// Styles
export default styled(NFTCard)`
  position: relative;
  padding: 20px 20px 25px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 10px 20px rgba(24, 27, 33, 0.1);
  font-size: 14px;

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 10px 10px 20px;
  }

  ${CardOwner} {
    margin-bottom: 5px;
  }

  a {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }

  .card-image {
    padding-top: 100%;
    margin: -20px -20px 20px;
    background-size: cover;
    background-position: center center;
  }
  .pricing {
    margin-bottom: 10px;
    font-weight: 700;

    strong {
      color: ${props => props.theme.colors.secondary}
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
  }
  .favorite {
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
    transition: color .15s;

    &.is-favorite,
    &:hover {
      color: ${props => props.theme.colors.quaternary};
    }
  }
`;