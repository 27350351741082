import { createContext, Dispatch, SetStateAction, useReducer, useState } from 'react';
import * as en from '../languages/en.json';
import * as de from '../languages/de.json';

interface LanguageContextInterface {
  currentLanguage: any,
  setCurrentLanguage: Dispatch<SetStateAction<any>>,
  languages: any
}

interface Props {
  children: Array<React.ReactChild> | React.ReactChild
}

export const LanguageContext = createContext<LanguageContextInterface>({} as LanguageContextInterface);

function LanguageContextProvider(props: Props) {
  const [languages] = useState<any>({
    en,
    de
  });
  const [currentLanguage, setCurrentLanguage] = useReducer<any>((state: any, action: string) => {
    if (action in languages) {
      window.localStorage.setItem('lang', action);

      return languages[action];
    } else {
      return languages.en;
    }
  }, languages[window.localStorage.getItem('lang') ?? 'en']);

  return <LanguageContext.Provider value={{
    currentLanguage,
    setCurrentLanguage,
    languages
  }}>{props.children}</LanguageContext.Provider>
}

export default LanguageContextProvider;