import { createContext, Dispatch, SetStateAction, useState } from 'react';

interface NotificationContextInterface {
  notification?: string | undefined,
  setNotification: Dispatch<SetStateAction<string | undefined>>
}

interface Props {
  children: Array<React.ReactChild> | React.ReactChild
}

export const NotificationContext = createContext<NotificationContextInterface>({} as NotificationContextInterface);

function NotificationContextProvider(props: Props) {
  const [notification, setNotification] = useState<string>();

  return <NotificationContext.Provider value={{
    notification,
    setNotification
  }}>{props.children}</NotificationContext.Provider>
}

export default NotificationContextProvider;