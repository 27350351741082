import { useContext, useEffect } from 'react'
import CollectionImporter from '../components/CollectionImporter'
import IntroAlt from '../components/IntroAlt'
import Section from '../components/Section'
import { AppContext }  from '../context/AppContext'
import { LanguageContext } from '../context/LanguageContext'

export default function AddCollection() {
  const { setHeaderIsAlt } = useContext(AppContext);
  const { currentLanguage } = useContext(LanguageContext);

  document.title = 'Add a collection';

  useEffect(() => {
    setHeaderIsAlt(true);
  }, [])

  return (
    <>
      <IntroAlt title={currentLanguage.addCollection.intro.title} />

      <main className='main'>
        <div className='container'>
          <Section>
            <CollectionImporter />
          </Section>
        </div>
      </main>
    </>
  )
}
