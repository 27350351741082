import { createGlobalStyle } from 'styled-components';
import { rgba } from 'polished';

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  body {
    overflow-y: scroll;
  }

  // Global containers
  body {
    font-family: ${props => props.theme.fonts.sansSerif}, sans-serif;
    font-size: 16px;
    line-height: 1.2;
    color: ${props => props.theme.colors.senary};
    background: ${props => props.theme.colors.tertiary};
  }

  img {
    max-width: 100%;
    height: auto;
  }

  a {
    color: ${props => props.theme.colors.primary};
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-family: ${props => props.theme.fonts.sansSerifSecondary}, sans-serif;
    line-height: 1.2;
    font-weight: 700;
    color: ${props => props.theme.colors.secondary};
    margin: 0 0 20px;

    &:last-child {
      margin: 0;
    }
  }

  h1,
  .h1 {
    font-size: 50px;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 38px;
    }
  }

  h2,
  .h2 {
    font-size: 40px;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 32px;
    }
  }

  h4,
  .h4 {
    font-size: 24px;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 20px;
    }
  }

  h5,
  .h5 {
    font-family: ${props => props.theme.fonts.sansSerif}, sans-serif;
    font-size: 18px;
    font-style: normal;
  }

  .title-underlined {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 35px;

    &:before {
      content: '';
      position: absolute;
      width: 100px;
      height: 4px;
      left: 50%;
      bottom: 0;
      margin-left: -55px;
      background: ${props => props.theme.colors.septenary};
    }
  }

  .text-center {
    text-align: center;
  }

  .container {
    max-width: 1320px;
    flex: 0 1 100%;
    padding: 0 12px;
    margin: 0 auto;
  }

  .wrapper {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
  }
  
  // Buttons
  .btn {
    display: inline-block;
    min-width: 130px;
    padding: 11px 20px;
    vertical-align: middle;
    border: 0;
    border-radius: 38px;
    font-size: 14px;
    font-weight: 700;
    color: ${props => props.theme.colors.secondary};
    text-decoration: none;
    text-align: center;
    background: ${props => props.theme.colors.septenary};
    cursor: pointer;
    appearance: none;

    &:hover {
      color: ${props => props.theme.colors.tertiary};
      background: ${props => props.theme.colors.secondary};
    }
    &.btn-alt {
      color: ${props => props.theme.colors.secondary};
      transition: background .15s;
      background: ${props => props.theme.colors.quinary};

      &:hover {
        box-shadow: none;
        background: ${props => props.theme.colors.primary};
      }
    }
    &.btn-mini {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }

  // Link
  .link {
    font-weight: 700;
    text-decoration: none;
    transition: color .15s;

    &:hover {
      color: ${props => props.theme.colors.quaternary};
    }
  }

  // Form elements
  .form-error {
    color: ${props => props.theme.colors.quaternary};
  }
  .form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: 700;
    color: ${props => props.theme.colors.secondary};

    .required {
      color: #eb5757;
    }
  }
  .form-row {

    + .form-row {
      margin-top: 15px;
    }
  }
  .form-file {
    position: relative;

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
  .form-combined-fields {

    input {
      border-radius: 0;

      &:first-child {
        border-bottom: 0;
        border-radius: 22px 22px 0 0;
      }
      &:last-child {
        border-top: 0;
        border-radius: 0 0 22px 22px;
      }
    }
  }
  .form-checkbox {
    position: relative;

    input {
      position: absolute;
      opacity: 0;
      visibility: hidden;

      &:checked {

        + label {

          &:before {
            content: '✓';
            background: ${props => props.theme.colors.primary};
          }
        }
      }
    }

    .form-label {
      position: relative;
      padding-left: 35px;
      margin: 0;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        top: 50%;
        left: 0;
        margin-top: -12px;
        border: 2px solid ${props => props.theme.colors.primary};
        border-radius: 4px;
        line-height: 18px;
        color: ${props => props.theme.colors.tertiary};
        text-align: center;
        transition: background .15s;
      }
    }
  }
  .form-field {
    display: block;
    width: 100%;
    height: 42px;
    padding: 0 15px;
    border: 2px solid ${props => props.theme.colors.primary};
    border-radius: 22px;
    font-size: 16px;
    color: ${props => props.theme.colors.secondary};
    background: transparent;
    appearance: none;

    &:focus {
      outline: none;
    }

    &.form-field-textarea {
      padding-top: 12px;
      padding-bottom: 12px;
      height: 200px;
    }
  }
  textarea.form-field {
    height: 200px; 
    padding-top: 12px;
    padding-bottom: 12px; 
  }
  .form-select {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 10px; 
      height: 10px;
      top: 50%;
      right: 13px;
      margin-top: -7px; 
      border-top: 2px solid ${props => props.theme.colors.primary};
      border-right: 2px solid ${props => props.theme.colors.primary};
      transform: rotate(135deg);
      pointer-events: none;
    }
    
    .form-field {
      padding-right: 40px;
      cursor: pointer;
    }
  }

  // NFT actions modal
  .nft-action-modal {

    &.alternative {
      padding: 15px 20px;
    }

    .current-offer {
      max-width: 180px;
      display: block;
      padding: 15px 20px;
      margin: 0 auto 10px;
      border: 1px solid ${props => props.theme.colors.primary};
      border-radius: 10px;
      text-align: left;

      strong {
        color: ${props => props.theme.colors.secondary};
      }
    }

    .offer-field,
    .bid-field {
      padding: 10px 0;
    }
    .form-error {
      display: block;
      margin-top: 5px;

      &:not(.is-active) {
        display: none;
      }
    }
    .title {
      margin-bottom: 10px;
      font-size: 20px;
      color: ${props => props.theme.colors.secondary};
      text-align: center;

      &:only-child {
        margin: 0;
      }
    }
    .actions {
      text-align: center;
    }
    .step {
      padding: 15px 20px;

      &:not(.is-active) {
        background: ${props => props.theme.colors.quinary};

        > *:not(.step-title) {
          display: none;
        }
      }
      &.is-completed {

        .step-title {

          &:before {
            content: '✓ ';
          }
        }
      }

      p {

        &:last-child {
          margin-bottom: 0;
        }
      }

      .step-price {
        max-width: 120px;
        margin-bottom: 15px;

        @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
          margin: 0 auto 15px;
        }
      }

      .step-auction-end {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        input {
          flex: 0 1 200px;
        }
        span {
          margin: 0 10px;
        }
      }

      .step-title {
        font-weight: 700;
        color: ${props => props.theme.colors.secondary};
      }

      + .step {
        border-top: 1px solid ${props => props.theme.colors.secondary};
      }
    }
  }
`;


export default GlobalStyle;