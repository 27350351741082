import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/AppContext';
import { NotificationContext } from '../context/NotificationContext';
import { UserContext } from '../context/UserContext';
import { useLazyGetUserViaTokenQuery } from '../features/userApi';
import { getTokenFromStorage } from '../utils/Storage';
import AccountToolbar from './AccountToolbar';
import Logo from './Logo';
import NavBar from './NavBar';

interface Props {
  className?: string
}

function Header(props: Props) {
  // State vars
  const { headerIsAlt } = useContext(AppContext);
  const [headerFixedClass, setHeaderFixedClass] = useState<string>('');
  const [navIsOpen, setNavIsOpen] = useState<boolean>(false);
  const [connectUser] = useLazyGetUserViaTokenQuery();
  const { setUser } = useContext(UserContext);
  const token = getTokenFromStorage();
  const { setNotification } = useContext(NotificationContext);

  // Methods
  const fixHeader = () => {
    if (window.pageYOffset > 0) {
      setHeaderFixedClass('is-fixed');
    } else {
      setHeaderFixedClass('');
    }
  }

  // Sticky header color
  useEffect(() => {
    window.addEventListener('scroll', fixHeader);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', fixHeader);
    }
  });

  // Automatic wallet connection
  useEffect(() => {
    if (token) {
      connectUser(token).then(response => {
        setUser(response.data);

        setNotification('Logged in successfully!');
      }).catch(err => {
        setNotification('An unexpected error has occurred!');

        console.error(err)
      });;
    }
  }, []);

  return (
    <div className={`${props.className} ${headerFixedClass} ${headerIsAlt ? 'header-alt' : ''}`}>
      <div className='container'>
        <div className='header-logo'>
          <Logo />
        </div>

        <div className={`header-btn ${navIsOpen ? 'nav-open' : ''}`} onClick={e => setNavIsOpen(!navIsOpen)}>
          <span></span><span></span><span></span>
        </div>

        <div className={`header-group ${navIsOpen ? 'nav-open' : ''}`} onClick={e => setNavIsOpen(false)}>
          <NavBar />

          <div className='header-action'>
            <AccountToolbar />
          </div>
        </div>
      </div>
    </div>
  )
}

export default styled(Header)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 20px 0;
  border-bottom: 1px solid transparent;
  z-index: 5;
  transition: border-color .15s, background .15s;

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 10px 0;
  }

  &.is-fixed {
    background: ${props => props.theme.colors.quinary};
  }
  &.header-alt {
    background: ${props => props.theme.colors.quinary};

    &.is-fixed {
      border-color: transparent;
    }
  }
  .header-btn {
    display: none;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      position: relative;
      display: flex;
      width: 50px;
      height: 50px;
      flex-flow: row wrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      border-radius: 50%;
      background: ${props => props.theme.colors.tertiary};
      z-index: 7;

      &.nav-open {

        span {
          
          &:first-child {
            transform: translateY(6px) rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            transform: translateY(-6px) rotate(-45deg);
          }
        }
      }

      span {
        display: block;
        width: 26px;
        height: 2px;
        margin: 0 auto;
        background: ${props => props.theme.colors.secondary};
        transition: transform .15s, opacity .15s;

        + span {
          margin-top: 4px;
        }
      }
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-group {
    display: flex;
    align-items: center;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 25px;
      flex-flow: column nowrap;
      justify-content: center;
      align-content: center;
      overflow-x: hidden;
      overflow-y: auto;
      opacity: 0;
      visibility: hidden;
      background: ${props => props.theme.colors.tertiary};
      transition: opacity .15s, visibility .15s;
      z-index: 6;

      &.nav-open {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .header-action {
    margin-left: 25px;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      margin: 35px 0 0;
      text-align: center;
    }
  }
`;