import { WalletConnection } from 'near-api-js';
import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LanguageContext } from '../context/LanguageContext';
import { NotificationContext } from '../context/NotificationContext';
import { UserContext } from '../context/UserContext';
import { useAddUserMutation, useLazyGetUserQuery } from '../features/userApi';
import { getConnection } from '../utils/NEAR';
import Loader from './Loader';

interface Props {
  className?: string
}

export default function WalletConnector(props: Props) {
  const [fetchUser, userData] = useLazyGetUserQuery();
  const [addUser, addUserData] = useAddUserMutation();
  const { setUser } = useContext(UserContext);
  const { setNotification } = useContext(NotificationContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentLanguage } = useContext(LanguageContext);
  
  useEffect(() => {
    if (window.localStorage.getItem('nearinitiated')) {
      window.localStorage.removeItem('nearinitiated');

      const params = new URLSearchParams(window.location.search);

      if (params.has('account_id')) {
        const walletId = params.get('account_id') as string;
        const key = params.get('all_keys') as string;

        fetchUser(key).then(async response => {
          const nearConnection = await getConnection();
          const wallet = new WalletConnection(nearConnection, '');
          
          if (!response.data) {
            addUser({ name: walletId, patch: { walletId, key } }).then(response => {
              if ('data' in response) {
                setUser(response.data);

                setNotification(currentLanguage.profileNav.successNotification);
              }
            }).catch(err => {
              setNotification(currentLanguage.profileNav.errorNotification);

              console.error(err)
            });
          } else {
            setUser(response.data);
          }
        })
      }
    }
  }, []);

  /**
   * Method to initiate the wallet connection
   */
  const connectWallet = async () => {
    const nearConnection = await getConnection();
    const wallet = new WalletConnection(nearConnection, '');

    window.localStorage.setItem('nearinitiated', '1');

    await wallet.requestSignIn();
  }

  return (
    <>
      <div className='btn' onClick={connectWallet}>
        <Loader width={10} height={10} isLoading={(userData.isLoading || addUserData.isLoading)}>{currentLanguage.profileNav.button}</Loader>
      </div>
    </>
  )
}