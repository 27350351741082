import { skipToken } from '@reduxjs/toolkit/dist/query';
import { WalletConnection } from 'near-api-js';
import { parseNearAmount } from 'near-api-js/lib/utils/format';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LanguageContext } from '../context/LanguageContext';
import { NotificationContext } from '../context/NotificationContext';
import { useGetCollectionQuery } from '../features/collectionsApi';
import { useUpdateOffersMutation } from '../features/nftApi';
import { getConnection } from '../utils/NEAR';
import Loader from './Loader';
import NFTMiniShowcase from './NFTMiniShowcase';

interface Props {
  className?: string,
  nft: NFT,
  reset?: boolean
}

export default function NFTOffer(props: Props) {
  const collectionData = useGetCollectionQuery(props.nft.contractId ?? skipToken);
  const [isBidding, setIsBidding] = useState<boolean>(false);
  const [offerAmount, setOfferAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasOffer, setHasOffer] = useState<boolean>(false);
  const [updateOffers] = useUpdateOffersMutation();
  const { setNotification } = useContext(NotificationContext);
  const { currentLanguage } = useContext(LanguageContext);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (props.reset) {
      timer = setTimeout(() => {
        setIsBidding(false);
        setOfferAmount(0);
        setIsLoading(false);
        setHasOffer(false);
      }, 320)
    }

    return () => clearTimeout(timer);
  }, [props.reset]);

  useEffect(() => {
    if (searchParams.has('errorCode') && window.localStorage.getItem('offering')) {
      searchParams.delete('errorCode');
      searchParams.delete('errorMessage');

      setSearchParams(searchParams);

      window.localStorage.removeItem('offering');
    }

    if (window.localStorage.getItem('offering') && searchParams.has('transactionHashes')) {
      window.localStorage.removeItem('offering');

      updateOffers({ nft: { contractId: props.nft.contractId, tokenId: props.nft.tokenId }});
    }
  }, [])
  
  const handleOffer = async () => {
    if (!isLoading && offerAmount > 0) {
      let isError = false;

      setIsLoading(true);

      const nearConnection = await getConnection();
      const wallet = new WalletConnection(nearConnection, '');

      window.localStorage.setItem('offering', '1');

      await wallet.account().functionCall({ contractId: process.env.REACT_APP_NEAR_MARKET_WALLET_ID ?? '', methodName: 'offer', args: { tokenId: props.nft.tokenId.toString(), contractId: props.nft.isHosted ? (process.env.REACT_APP_NFT_HOST ?? '') : props.nft.contractId, collectionId: props.nft.isHosted ? props.nft.contractId : undefined }, attachedDeposit: parseNearAmount(offerAmount.toString()) })
    }
  }
  
  return (
    <div className='nft-action-modal alternative'>
      {!hasOffer &&
        <>
          <div className='title'>{currentLanguage.nftOffer.title}</div>

          {collectionData.data &&
            <NFTMiniShowcase nft={props.nft} collection={collectionData.data} />
          }

          <div className='offer-field'>
            <label htmlFor='offer-field' className='form-label'>{currentLanguage.nftOffer.amount}</label>

            <input type='text' name='offer-field' id='offer-field' className='form-field' defaultValue={offerAmount} onChange={e => e.currentTarget.value ? setOfferAmount(parseInt(e.currentTarget.value)) : 0} />
          </div>

          <div className='actions'>
            <span className='btn' onClick={handleOffer}>
              <Loader isLoading={isLoading} width={10} height={10}>{currentLanguage.nftOffer.offerBtn}</Loader>
            </span>
          </div>
        </>
      }

      {hasOffer &&
        <div className='title'>Offer placed successfully!</div>
      }
    </div>
  )
}
