import { nanoid } from '@reduxjs/toolkit'
import React from 'react'

interface Props {
  data: Array<any>
}

export default function TermsRenderer(props: Props) {
  return (
    <>
      {
        props.data.map(item => {
          if ('type' in item) {
            switch (item.type) {
              case 'title':
                return <h3 key={nanoid()}>{item.data}</h3>

              case 'paragraph':
                return <p key={nanoid()} dangerouslySetInnerHTML={{ __html: item.data }}></p>

              case 'list':
                return (
                  <ul>
                    {
                      item.data.map((listItem: string) => <li key={nanoid()}>{listItem}</li>)
                    }
                  </ul>
                )
            }
          }

          return <></>;
        })
      }
    </>
  )
}
