import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LanguageContext } from '../context/LanguageContext';
import Columns from './Columns';
import FooterNav from './FooterNav';
import Logo from './Logo';
import Socials from './Socials';

interface Props {
  className?: string
}

function Footer(props: Props) {
  const { currentLanguage } = useContext(LanguageContext);

  return (
    <footer className={props.className}>
      <div className='footer-group'>
        <div className='container'>
          <Columns data={[
            {
              "title": currentLanguage.footer.navTitles.marketplace,
              "links": [
                {
                  "text": "Link",
                  "url": "/link"
                },
                {
                  "text": "Link",
                  "url": "/link"
                },
                {
                  "text": "Link",
                  "url": "/link"
                },
                {
                  "text": "Link",
                  "url": "/link"
                },
                {
                  "text": "Link",
                  "url": "/link"
                },
                {
                  "text": "Link",
                  "url": "/link"
                }
              ]
            },
            {
              "title": currentLanguage.footer.navTitles.resources,
              "links": [
                {
                  "text": "Link",
                  "url": "/link"
                },
                {
                  "text": "Link",
                  "url": "/link"
                },
                {
                  "text": "Link",
                  "url": "/link"
                },
                {
                  "text": "Link",
                  "url": "/link"
                },
                {
                  "text": "Link",
                  "url": "/link"
                },
                {
                  "text": "Link",
                  "url": "/link"
                }
              ]
            },
            {
              "title": currentLanguage.footer.navTitles.community,
              "links": [
                {
                  "text": "Link",
                  "url": "/link"
                },
                {
                  "text": "Link",
                  "url": "/link"
                },
                {
                  "text": "Link",
                  "url": "/link"
                },
                {
                  "text": "Link",
                  "url": "/link"
                },
                {
                  "text": "Link",
                  "url": "/link"
                },
                {
                  "text": "Link",
                  "url": "/link"
                }
              ]
            }
          ]} component={FooterNav} columns={4}></Columns>
        </div>
      </div>

      <div className='footer-row'>
        <div className='container'>
          <div className='footer-inner'>
            <Logo />

            <p className='copyright'>© Copyright 2022</p>

            <ul className='nav-lang'>
              <li><Link to={'/privacy-policy'}>{currentLanguage.privacyPolicy.intro.title}</Link></li>

              <li><Link to={'/terms-of-service'}>{currentLanguage.termsOfService.intro.title}</Link></li>
            </ul>
          </div>

          <div className='footer-stuff'>
            <div className='footer-socials'>
              <Socials />
            </div>

            <div className='crypto-valley'>
              <img src='/assets/crypto-valley.png' alt='Crypto Valley member'/>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default React.memo(styled(Footer)`
  border-top: 1px solid ${props => props.theme.colors.primary};
  background: ${props => props.theme.colors.tertiary};

  .columns-4 {

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {

      > * {
        flex: 0 0 45%;
        margin: 0 2.5% 5%;
      }
    }
  }

  .footer-socials {
    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      display: flex;
      justify-content: center;
    }
  }

  .footer-stuff {
    display: flex;
    align-items: center;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      display: block;
    }
  }
  .crypto-valley {
    width: 170px;
    margin-left: 10px;
    text-align: center;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      margin: 10px auto 0;
    }

    img {
      display: block;
    }
  }
  .nav-lang {
    display: flex;
    justify-content: center;
    padding: 0 10px;
    list-style-type: none;

    li {

      + li {
        margin-left: 10px;
      }
    }
    a {
      text-decoration: none;
      transition: color .15s;

      &:hover {
        color: ${props => props.theme.colors.secondary};
      }
    }
  }
  .footer-group {
    padding: 50px 0;
  }
  .footer-row {
    padding: 20px 0;
    border-top: 1px solid ${props => props.theme.colors.primary};

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
        display: block;
      }
    }
  }
  .footer-inner {
    display: flex;
    align-items: center;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      display: block;
      text-align: center;
    }

    ${Logo} {
      margin-right: 30px;

      @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
        margin: 0;
      }
    }
  }
  .footer-nav {
    font-size: 16px;

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      text-align: center;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }
    li {

      + li {
        margin-top: 10px;
      }
    }
    a {
      color: ${props => props.theme.colors.primary};
      text-decoration: none;
      transition: color .15s;

      &:hover {
        color: ${props => props.theme.colors.secondary};
      }
    }
  }
`);
