import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  className?: string
}

// Component
function Logo(props: Props) {
  return (
    <Link to='/' className={props.className} style={{ backgroundImage: `url(/assets/logo.png)`}}>LogoText</Link>
  )
}

// Styles
export default styled(Logo)`
  display: inline-block;
  width: 105px;
  height: 50px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  font-size: 0;
  line-height: 0;
  text-indent: -4000px;
`;