import '@fortawesome/fontawesome-free/css/all.min.css';
import 'animate.css/animate.min.css';
import 'normalize.css';
import { Buffer } from 'buffer';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactDOM from 'react-dom';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'swiper/css';
import Footer from './components/Footer';
import Header from './components/Header';
import Notification from './components/Notification';
import RootProvider from './context/RootContext';
import AddCollection from './pages/AddCollection';
import Collection from './pages/Collection';
import EditCollection from './pages/EditCollection';
import Explore from './pages/Explore';
import Homepage from './pages/Homepage';
import MyCollections from './pages/MyCollections';
import MyNFTs from './pages/MyNFTs';
import NFT from './pages/NFT';
import Ranking from './pages/Ranking';
import User from './pages/User';
import EditProfile from './pages/EditProfile';
import TwitterConnector from './components/TwitterConnector';
import MyOffers from './pages/MyOffers';
import CreateCollection from './pages/CreateCollection';
import AddToCollection from './pages/AddToCollection';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

(window as any).Buffer = Buffer;

function App() {
  return (
    <React.StrictMode>
      <RootProvider>
        <BrowserRouter>
          <ScrollAnimation animateIn='animate__fadeIn' animateOnce={true} offset={0} style={{ position: 'relative', zIndex: 5 }}>
            <Header />
          </ScrollAnimation>

          <Routes>
            <Route path='/' element={<Homepage />} />

            <Route path='/explore' element={<Explore />} />

            <Route path='/ranking' element={<Ranking />} />

            <Route path='/add-collection' element={<AddCollection />} />

            <Route path='/collection/:contractId/:tokenId' element={<NFT />} />

            <Route path='/collection/:contractId/edit' element={<EditCollection />} />

            <Route path='/collection/:contractId' element={<Collection />} />

            <Route path='/my-collections' element={<MyCollections />} />

            <Route path='/user/:walletId' element={<User />} />

            <Route path='/my-nfts' element={<MyNFTs />} />

            <Route path='/my-offers' element={<MyOffers />} />

            <Route path='/edit-profile' element={<EditProfile />} />

            <Route path='/create-collection' element={<CreateCollection />} />

            <Route path='/mint/:contractId' element={<AddToCollection />} />

            <Route path='/privacy-policy' element={<PrivacyPolicy />} />

            <Route path='/terms-of-service' element={<TermsOfService />} />

            <Route path='/twitter/oauth' element={<TwitterConnector />} />
          </Routes>

          <ScrollAnimation animateIn='animate__fadeIn' animateOnce={true} offset={0}>
            <Footer />
          </ScrollAnimation>

          <Notification />
        </BrowserRouter>
      </RootProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
