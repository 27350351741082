import { createContext, Dispatch, SetStateAction, useState } from 'react';

interface AppContextInterface {
  headerIsAlt: boolean,
  setHeaderIsAlt: Dispatch<SetStateAction<boolean>>
}

interface Props {
  children: Array<React.ReactChild> | React.ReactChild
}

export const AppContext = createContext<AppContextInterface>({} as AppContextInterface);

function AppContextProvider(props: Props) {
  const [headerIsAlt, setHeaderIsAlt] = useState<boolean>(false);

  return <AppContext.Provider value={{
    headerIsAlt,
    setHeaderIsAlt
  }}>{props.children}</AppContext.Provider>
}

export default AppContextProvider;