import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useState } from 'react';
import CollectionCard from '../components/CollectionCard';
import Columns from '../components/Columns';
import IntroAlt from '../components/IntroAlt';
import Loader from '../components/Loader';
import Section from '../components/Section';
import { AppContext } from '../context/AppContext';
import { LanguageContext } from '../context/LanguageContext';
import { UserContext } from '../context/UserContext';
import { useGetUserCollectionsQuery } from '../features/collectionsApi';
import { useGetUserQueryState } from '../features/userApi';

export default function MyCollections() {
  const { user } = useContext(UserContext);
  const [page, setPage] = useState<number>(1);
  const [collections, setCollections] = useState<Array<Collection>>([]);
  const [stopFetching, setStopFetching] = useState<boolean>(false);
  const collectionsData = useGetUserCollectionsQuery({ walletId: user?.walletId ?? '', page });
  const { setHeaderIsAlt } = useContext(AppContext);
  const { currentLanguage } = useContext(LanguageContext);

  document.title = 'My collections';

  useEffect(() => {
    setHeaderIsAlt(true);
  }, [])

  useEffect(() => {
    if (collectionsData.data && !collectionsData.isLoading && !collectionsData.isFetching) {
      setCollections([...collections, ...collectionsData.data]);

      setStopFetching(collectionsData.data.length === 0);
    }
  }, [collectionsData.data, page])

  return (
    <>
      <IntroAlt title={currentLanguage.myCollections.intro.title} />

      <main className='main'>
        <div className='container'>
          <Section>
            <Loader isLoading={(collectionsData.isLoading)}>
              {
                (() => {
                  if (collections.length || collectionsData.isFetching) {
                    return (
                      <>
                        <Columns columns={3} component={CollectionCard} data={collections} allowEdit={true} page={page} setPage={(data: number) => setPage(data)} shouldFetch={stopFetching ? false : !collectionsData.isFetching} />

                        <Loader isLoading={collectionsData.isFetching}><></></Loader>
                      </>
                    )
                  } else {
                    return <p className='text-center'>{currentLanguage.myCollections.noCollections}</p>
                  }
                })()
              }
            </Loader>
          </Section>
        </div>
      </main>
    </>
  )
}
