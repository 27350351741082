import { nanoid } from '@reduxjs/toolkit';
import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

interface Props {
  className?: string,
  data?: Array<any>,
  component: React.ComponentType<any>,
  sliderOptions: any
}

// Component
function Slider(props: Props) {
  const LocalComponent: React.ComponentType<any> = props.component;

  return (
    <div className={props.className}>
      <Swiper
        modules={[Navigation]}
        slidesPerView={props.sliderOptions.slidesPerView}
        spaceBetween={props.sliderOptions.spaceBetween}
        breakpoints={props.sliderOptions.breakpoints}
        navigation={{
          enabled: true
        }}
      >
        {
          props.data?.map(dataItem => {
            return (
              <SwiperSlide key={nanoid()}>
                <LocalComponent data={dataItem} />
              </SwiperSlide>
            );
          })
        }
      </Swiper>
    </div>
  )
}

// Styles
export default styled(Slider)`
  padding: 0 23px;
  margin: -25px -23px;
  overflow: hidden;

  @media only screen and (max-width: ${props => props.theme.breakpoints.lg}) {
    margin: 0;
  }

  .swiper {
    overflow: visible;

    .swiper-wrapper {
      padding: 25px 0;
    }
    [class^="swiper-button"] {
      position: absolute;
      width: 46px;
      height: 46px;
      top: 50%;
      right: -22px;
      margin-top: -23px;
      border-radius: 50%;
      background: ${props => props.theme.colors.primary};
      z-index: 2;
      cursor: pointer;

      &:hover {

        &:before {
          opacity: 1;
        }
      }
      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 10px;
        height: 10px;
        top: 50%;
        left: 50%;
        margin: -5px 0 0 -7px;
        border-top: 3px solid ${props => props.theme.colors.secondary};
        border-right: 3px solid ${props => props.theme.colors.secondary};
        transform: rotate(45deg);
        transition: opacity .15s;
      }
      &.swiper-button-prev {
        left: -23px;
        
        &:before {
          margin-left: -3px;
          transform: rotate(-135deg);
        }
      }
    }
  }
`;