import { WalletConnection } from 'near-api-js';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LanguageContext } from '../context/LanguageContext';
import { NotificationContext } from '../context/NotificationContext';
import { useCancelSaleOrAuctionMutation } from '../features/nftApi';
import { getConnection } from '../utils/NEAR';
import { getTokenFromStorage } from '../utils/Storage';
import Loader from './Loader';

interface Props {
  className?: string,
  nft: NFT,
  type: string,
  reset?: boolean
}

export default function NFTCancelContract(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasCancelled, setHasCancelled] = useState<boolean>(false);
  const [cancelActions] = useCancelSaleOrAuctionMutation();
  const { setNotification } = useContext(NotificationContext);
  const { currentLanguage } = useContext(LanguageContext);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (props.reset) {
      timer = setTimeout(() => {
        setIsLoading(false);
        setHasCancelled(false);
      }, 320);
    }

    return () => clearTimeout(timer);
  }, [props.reset]);

  useEffect(() => {
    if (searchParams.has('errorCode') && window.localStorage.getItem('cancelling-sale')) {
      searchParams.delete('errorCode');
      searchParams.delete('errorMessage');

      setSearchParams(searchParams);

      window.localStorage.removeItem('cancelling-sale');
    }

    if (window.localStorage.getItem('cancelling-sale') && searchParams.has('transactionHashes')) {
      window.localStorage.removeItem('cancelling-sale');

      cancelActions({ nft: { tokenId: props.nft.tokenId, contractId: props.nft.contractId, token: getTokenFromStorage() ?? '' }});
    }
  }, [])

  const handleCancelContract = async () => {
    if (!isLoading) {
      let isError = false;

      setIsLoading(true);

      const nearConnection = await getConnection();
      const wallet = new WalletConnection(nearConnection, '');

      window.localStorage.setItem('cancelling-sale', '1');
      
      if (props.type === 'auction') {
        await wallet.account().functionCall({ contractId: process.env.REACT_APP_AUCTIONER_ID ?? '', methodName: 'cancelAuction', args: { tokenId: props.nft.tokenId.toString(), contractId: props.nft.isHosted ? (process.env.REACT_APP_NFT_HOST ?? '') : props.nft.contractId, collectionId: props.nft.isHosted ? props.nft.contractId : undefined }, attachedDeposit: '1' });
      } else {
        await wallet.account().functionCall({ contractId: process.env.REACT_APP_NEAR_MARKET_WALLET_ID ?? '', methodName: 'cancelSale', args: { tokenId: props.nft.tokenId.toString(), contractId: props.nft.isHosted ? (process.env.REACT_APP_NFT_HOST ?? '') : props.nft.contractId, collectionId: props.nft.isHosted ? props.nft.contractId : undefined }, attachedDeposit: '1' });
      }
    }
  }

  return (
    <div className='nft-action-modal alternative'>
      {!hasCancelled &&
        <>
          <div className='title'>{currentLanguage.nftCancelContract.title}</div>

          <p className='text-center'>{currentLanguage.nftCancelContract.text}</p>

          <div className='actions'>
            <span className='btn' onClick={handleCancelContract}>
              <Loader isLoading={isLoading} width={10} height={10}>{currentLanguage.nftCancelContract.cancelBtn}</Loader>
            </span>
          </div>
        </>
      }

      {hasCancelled &&
        <div className='title'>{`${props.type.slice(0, 1).toUpperCase()}${props.type.slice(1, props.type.length)}`} cancelled successfully!</div>
      }
    </div>
  )
}
