import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import nftApi from '../features/nftApi';
import userApi from '../features/userApi';
import usersApi from '../features/usersApi';
import collectionsApi from '../features/collectionsApi';

export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [nftApi.reducerPath]: nftApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [collectionsApi.reducerPath]: collectionsApi.reducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(userApi.middleware).concat(collectionsApi.middleware).concat(nftApi.middleware).concat(usersApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
