import { nanoid } from '@reduxjs/toolkit';
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { LanguageContext } from '../context/LanguageContext';

interface Props {
  className?: string,
  isForSale: boolean,
  traits?: Array<{ trait_type: string, value: string, occurance: string }>,
  setIsForSale: (data: boolean) => void,
  setIsForAuction: (data: boolean) => void,
  setLowPrice: (data?: number) => void,
  setHighPrice: (data?: number) => void,
  setName: (data?: string) => void,
  setSortBy: (data?: string) => void,
  setTraits: (data?: string) => void,
  isOpen?: boolean
}

let rangeDebounce : ReturnType<typeof setTimeout>;
let searchDebounce : ReturnType<typeof setTimeout>;

function CollectionSidebar(props: Props) {
  const lowRangeRef = useRef<HTMLInputElement>(null);
  const highRangeRef = useRef<HTMLInputElement>(null);
  const [openedAccordions, setOpenedAccordions] = useState<Array<number>>([]);
  const [selectedTraits, setSelectedTraits] = useState<Array<string>>([]);
  const { currentLanguage } = useContext(LanguageContext);

  const handleRange = () => {
    clearTimeout(rangeDebounce);
    
    rangeDebounce = setTimeout(() => {
      if (lowRangeRef.current && highRangeRef.current) {
        const lowRangeValue = parseInt(lowRangeRef.current.value);
        const highRangeValue = parseInt(highRangeRef.current.value);
  
        props.setIsForSale(!isNaN(lowRangeValue) || !isNaN(highRangeValue));
  
        props.setLowPrice(isNaN(lowRangeValue) ? undefined : lowRangeValue);
        props.setHighPrice(isNaN(highRangeValue) ? undefined : highRangeValue);
      }
    }, 1000);
  }

  const handleSearch = (name: string) => {
    clearTimeout(searchDebounce);

    searchDebounce = setTimeout(() => {
      props.setName(name);
    }, 1000);
  }

  useEffect(() => {
    props.setTraits(selectedTraits.length ? selectedTraits.toString() : undefined);
  }, [selectedTraits]);

  return (
    <div className={`${props.className} ${props.isOpen ? 'filters-open' : ''}`}>
      <div className='collection-sidebar-section'>
        <div className='h5'>{currentLanguage.collectionShowcase.search.label}</div>

        <div className='sidebar-row'>
          <input type='text' className='form-field' name='search' id='name' onChange={e => handleSearch(e.currentTarget.value)} placeholder={currentLanguage.collectionShowcase.search.placeholder} />
        </div>
      </div>

      <div className='collection-sidebar-section'>
        <div className='h5'>{currentLanguage.collectionShowcase.sort.label}</div>

        <div className='sidebar-row'>
          <div className='form-select'>
            <select name='sortBy' id='sortBy' className='form-field' defaultValue={'tokenId'} onChange={e => props.setSortBy(e.currentTarget.value)}>
              <option value='tokenId'>{currentLanguage.collectionShowcase.sort.options.idAsc}</option>
              <option value='-tokenId'>{currentLanguage.collectionShowcase.sort.options.idDesc}</option>
              <option value='salePrice'>{currentLanguage.collectionShowcase.sort.options.priceAsc}</option>
              <option value='-salePrice'>{currentLanguage.collectionShowcase.sort.options.priceDesc}</option>
              <option value='-createdAt'>{currentLanguage.collectionShowcase.sort.options.createdAsc}</option>
              <option value='createdAt'>{currentLanguage.collectionShowcase.sort.options.createdDesc}</option>
            </select>
          </div>
        </div>
      </div>

      <div className='collection-sidebar-section'>
        <div className='h5'>{currentLanguage.collectionShowcase.status.label}</div>

        <div className='sidebar-row form-checkbox'>
          <input type='checkbox' id='for-sale' name='for-sale' onChange={e => props.setIsForSale(e.currentTarget.checked)} checked={props.isForSale} />

          <label htmlFor='for-sale' className='form-label'>{currentLanguage.collectionShowcase.status.buy}</label>
        </div>

        <div className='sidebar-row form-checkbox'>
          <input type='checkbox' id='on-auction' name='on-auction' onChange={e => props.setIsForAuction(e.currentTarget.checked)} />

          <label htmlFor='on-auction' className='form-label'>{currentLanguage.collectionShowcase.status.auction}</label>
        </div>

      </div>

      <div className='collection-sidebar-section'>
        <div className='h5'>{currentLanguage.collectionShowcase.price.label}</div>

        <div className='sidebar-row range-row'>
          <input type='text' className='form-field' name='range-low' id='range-low' placeholder={currentLanguage.collectionShowcase.price.lowPlaceholder} ref={lowRangeRef} onChange={handleRange} />

          -

          <input type='text' className='form-field' name='range-high' id='range-high' placeholder={currentLanguage.collectionShowcase.price.highPlaceholder} ref={highRangeRef} onChange={handleRange} />
        </div>
      </div>

      {props.traits &&
        <div className='collection-sidebar-section'>
          <div className='h5'>Traits</div>

          {
            (() => {
              const traitTypes = props.traits.map(trait => trait.trait_type).filter((value, index, self) => self.indexOf(value) === index);

              return (
                <div className='collection-sidebar-accordion'>
                  {
                    traitTypes.map((traitType, i) => (
                      <div className={`collection-sidebar-accordion-section ${openedAccordions.indexOf(i) > -1 ? 'is-open' : ''}`} key={nanoid()}>
                        <div className='collection-sidebar-accordion-head' onClick={e => setOpenedAccordions(openedAccordions.indexOf(i) > -1 ? openedAccordions.filter(item => item !== i) : [...openedAccordions, i])}>{traitType}</div>
    
                        <div className='collection-sidebar-accordion-body'>
                          {props.traits && 
                            props.traits.filter(trait => trait.trait_type === traitType).map(item => (
                              <div className='sidebar-row form-checkbox' key={nanoid()}>
                                <input type='checkbox' id={`${item.trait_type}-${item.value}`} name={`${item.trait_type}-${item.value}`} checked={selectedTraits.indexOf(`${item.trait_type}-${item.value}`) > -1} onChange={e => e.currentTarget.checked ? setSelectedTraits([...selectedTraits, `${item.trait_type}-${item.value}`]) : setSelectedTraits(selectedTraits.filter(trait => trait !== `${item.trait_type}-${item.value}`))} />
    
                                <label htmlFor={`${item.trait_type}-${item.value}`} className='form-label'>{item.value}</label>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
              )
            })()
          }
        </div>
      }
    </div>
  )
}

export default styled(CollectionSidebar)`
  padding: 20px;
  border: 2px solid ${props => props.theme.colors.primary};
  border-radius: 10px;

  .sidebar-row {

    + .sidebar-row {
      margin-top: 15px;
    }
  }
  .range-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      flex: 0 0 90px;
    }
  }
  .collection-sidebar-accordion {
    max-height: 250px;
    padding-right: 10px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      border: 0;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 6px;
      -webkit-box-shadow: none;
      background: ${props => props.theme.colors.primary};
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }

    .collection-sidebar-accordion-section {

      &:not(.is-open) {
      
        .collection-sidebar-accordion-body {
          display: none;
        }
      }
      
      + .collection-sidebar-accordion-section {
        margin-top: 10px;
      }
    }
    .collection-sidebar-accordion-body {
      margin: 15px 0 25px;
    }
    .collection-sidebar-accordion-head {
      position: relative;
      font-size: 20px;
      font-weight: 700;
      color: ${props => props.theme.colors.secondary};
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        top: 50%;
        right: 8px;
        margin-top: -9px;
        transform: rotate(135deg);
        border-top: 2px solid ${props => props.theme.colors.primary};
        border-right: 2px solid ${props => props.theme.colors.primary};
      }
    }
  }
  .collection-sidebar-section {

    + .collection-sidebar-section {
      padding-top: 20px;
      margin-top: 25px;
      border-top: 2px solid ${props => props.theme.colors.primary};
    }
  }
`;
