import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import IntroAlt from '../components/IntroAlt';
import NFTShowcase from '../components/NFTShowcase';
import Section from '../components/Section';
import { AppContext }  from '../context/AppContext';

export default function NFT() {
  const { tokenId, contractId } = useParams();
  const { setHeaderIsAlt } = useContext(AppContext);

  document.title = 'Viewing NFT';

  useEffect(() => {
    setHeaderIsAlt(true);
  }, [])

  return (
    <>
      <IntroAlt />

      <main className='main'>
        <div className='container'>
          <Section>
            <NFTShowcase tokenId={tokenId ?? ''} contractId={contractId ?? ''} />
          </Section>
        </div>
      </main>
    </>
  )
}
