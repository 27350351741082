// Slice for user
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_REST_API ?? '' }),
  endpoints: builder => ({
    getTopSellers: builder.query<Array<User>, void>({
      query: () => '/users/top-sellers'
    }),
    getSingleUser: builder.query<User, string>({
      query: (walletId: string) => `/users/${walletId}`
    }),
    getUserOffers: builder.query<Array<NFT>, string>({
      query: (walletId: string) => `/users/offers/${walletId}`
    }),
  })
});

/**
 * Hooks export
 */
export const { useGetUserOffersQuery, useGetTopSellersQuery, useGetSingleUserQuery } = usersApi;

export default usersApi;