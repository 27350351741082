// Slice for user
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const nftApi = createApi({
  reducerPath: 'nftApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_REST_API ?? ''}),
  tagTypes: ['NFT'],
  endpoints: builder => ({
    getNftsForContract: builder.query<Array<NFT>, { 
      contractId: string,
      isForSale?: boolean,
      isForAuction?: boolean,
      gte?: number,
      lte?: number,
      page?: number,
      name?: string,
      sortBy?: string,
      traits?: string
    }>({
      query: (args) => ({ 
        url: `/nft/${args.contractId}`, 
        params: args 
      })
    }),
    getNftsForUser: builder.query<Array<NFT>, { walletId: string, page?: number }>({
      query: (args) => ({
        url: `/nft/user/${args.walletId}`,
        params: args
      })
    }),
    getNft: builder.query<NFT, { tokenId: string, contractId: string }>({
      query: (args) => `/nft/${args.contractId}/${args.tokenId}`,
      providesTags: ['NFT']
    }),
    getNewNfts: builder.query<Array<NFT>, void>({
      query: () => '/nft/new'
    }),
    cancelSaleOrAuction: builder.mutation<NFT, { nft: { contractId: string, tokenId: number, token: string }}>({
      query: ({ nft }) => ({
        url: `/nft/cancel/${nft.contractId}/${nft.tokenId}`,
        body: nft,
        method: 'PATCH'
      }),
      invalidatesTags: ['NFT']
    }),
    buyNFT: builder.mutation<NFT, { nft: { contractId: string, tokenId: number }, patch: { owner: string, price: number, token: string, isHosted?: boolean } }>({
      query: ({ nft, patch }) => ({
        url: `/nft/buy/${nft.contractId}/${nft.tokenId}`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: ['NFT']
    }),
    bidNft: builder.mutation<NFT, { nft: { contractId: string, tokenId: number }}>({
      query: ({nft}) => ({
        url: `/nft/auction/update/${nft.contractId}/${nft.tokenId}`,
        method: 'PATCH'
      }),
      invalidatesTags: ['NFT']
    }),
    updateOffers: builder.mutation<NFT, { nft: { contractId: string, tokenId: number }}>({
      query: ({ nft }) => ({
        url: `/nft/offer/update/${nft.contractId}/${nft.tokenId}`,
        method: 'PATCH'
      }),
      invalidatesTags: ['NFT']
    }),
    setNftForSale: builder.mutation<NFT, { nft: { contractId: string, tokenId: number }, patch: { isForSale: boolean, salePrice: number, wyvernAddress: string, token: string } }>({
      query: ({ nft, patch }) => ({
        url: `/nft/list/${nft.contractId}/${nft.tokenId}`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: ['NFT']
    }),
    setNftForAuction: builder.mutation<NFT, { nft: { contractId: string, tokenId: number }, patch: { isForAuction: boolean, salePrice: number, auctionAddress: string, auctionData: { end: Date }, token: string }}>({
      query: ({ nft, patch }) => ({
        url: `/nft/auction/${nft.contractId}/${nft.tokenId}`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: ['NFT']
    })
  })
});

/**
 * Hooks export
 */
export const { 
  useGetNftsForContractQuery,
  useGetNewNftsQuery,
  useGetNftQuery,
  useGetNftsForUserQuery,
  useSetNftForSaleMutation,
  useBuyNFTMutation,
  useSetNftForAuctionMutation,
  useBidNftMutation,
  useUpdateOffersMutation,
  useCancelSaleOrAuctionMutation
} = nftApi;

export default nftApi;