import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { nanoid } from '@reduxjs/toolkit';

interface Props {
  data?: any,
  className?: string
}

function FooterNav(props: Props) {
  return (
    <div className='footer-nav'>
      <h5>{props.data?.title}</h5>

      <nav>
        <ul>
          {
            props.data?.links.map((link: any) => <li key={nanoid()}><Link to={link.url}>{link.text}</Link></li>)
          }
        </ul>
      </nav>
    </div>
  )
}

export default styled(FooterNav)`

`;